import CheckIcon from '@mui/icons-material/Check';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import React, { ReactElement } from 'react';
import { ReferenceData } from '../../../App/apiWrapper';
import { useTranslation } from '../../../features/i18n/useTranslation';
import { getRefDataAttributeValue } from '../../../features/refdata/getRefDataAttributeValue';
import { contrast } from '../../../helpers/color/contrast';
import { I18nFragment } from '../I18nFragment';
import { MultiSelectProps } from './MultiSelectProps';
import { clonePropertyInReactElementChildren } from '../../../helpers/react/clonePropertyInReactElementChildren';

export const MultiSelectChip: React.FC<MultiSelectProps> = (props) => {
  const { options, value, onValueChange, variant, id, placeholder, showLabel = true, size } = props;

  const placeholderText = useTranslation(placeholder);

  return (
    <Autocomplete
      multiple
      fullWidth
      id="tags-standard"
      options={options}
      getOptionLabel={(option) => option.name}
      value={value}
      onChange={(_, newValue: ReferenceData[]) => {
        onValueChange(newValue);
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const color = getRefDataAttributeValue(option, 'COLOR');
          const textColor = contrast(color);
          const tagProps = getTagProps({ index });
          const { onDelete } = tagProps;

          return (
            <Chip
              {...(size ? { size } : {})}
              sx={{
                backgroundColor: color,
                color: textColor,
                '& .MuiChip-deleteIcon': {
                  color: textColor,
                },
              }}
              label={option.name}
              onTouchEnd={onDelete}
              {...tagProps}
              key={option.id}
            />
          );
        })
      }
      isOptionEqualToValue={(lh, rh) => lh.id === rh.id}
      renderOption={(props, option, { selected }) => (
        <MenuItem value={option.name} sx={{ justifyContent: 'space-between' }} {...props} key={option.id}>
          {option.name}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          {...(showLabel ? { label: <I18nFragment id={id} /> } : {})}
          placeholder={placeholderText}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: clonePropertyInReactElementChildren(
                params.InputProps.endAdornment as ReactElement,
                'onClick',
                'onTouchEnd',
              ),
            },
          }}
        />
      )}
    />
  );
};
