import React from 'react';
import { useAppSelector } from '../../../App/hooks';
import { withDisplayRule, WithDisplayRuleProps } from '../../../features/displayRules/withDisplayRule';
import { selectRefDataValues } from '../../../features/refdata/selectRefDataValues';
import { MultiSelectChip } from './MultiSelectChip';
import { MultiSelectProps } from './MultiSelectProps';

interface FormRdrMultiSelectChipComponentProps
  extends WithDisplayRuleProps,
    Omit<MultiSelectProps, 'options' | 'value'> {
  values: string[];
}

const FormRdrMultiSelectChipComponent: React.FC<FormRdrMultiSelectChipComponentProps> = (props) => {
  const { screenRef, actionRef, id, values, ...rest } = props;
  const options = useAppSelector(selectRefDataValues(screenRef, actionRef, id));
  const value = options.filter((option) => {
    return values.indexOf(option.id) >= 0;
  });

  return <MultiSelectChip {...rest} id={id} options={options} value={value} />;
};

export const FormRdrMultiSelectChip = withDisplayRule(FormRdrMultiSelectChipComponent);
