import Typography from '@mui/material/Typography';
import React from 'react';

export const Paragraph: React.FC = (props) => (
  <Typography
    {...props}
    variant={'body2'}
    component={'div'}
    sx={{
      marginTop: '16px',
      marginBottom: '16px',
    }}
  />
);
