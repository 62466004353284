import MuiAppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { AnonymousInfo } from './AnonymousInfo';
import { AppHeader } from './AppHeader';
import { UserAccountInfo } from './UserAccountInfo';
import { isAuthUserRouteExclude } from '../../../helpers/isAuthUserRouteExclude';

interface AppBarProps {}

export const AppBar: React.FC<AppBarProps> = () => {
  const { userAuthenticated } = useAuth();
  const { pathname } = location;
  const authenticated = userAuthenticated && !isAuthUserRouteExclude(pathname);

  return (
    <>
      <MuiAppBar position="static">
        <Container
          maxWidth={false}
          sx={{
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          <Toolbar disableGutters>
            <AppHeader />
            {authenticated && <UserAccountInfo />}
            {!authenticated && <AnonymousInfo />}
          </Toolbar>
        </Container>
      </MuiAppBar>
    </>
  );
};
