import { SvgIconProps } from '@mui/material';
import React from 'react';
import { Gender, RefIdType } from '../../App/types';
import { FemaleIcon } from '../icons/FemaleIcon';
import { MaleIcon } from '../icons/MaleIcon';

interface GenderIconProps extends SvgIconProps {
  gender: RefIdType;
}

export const GenderIcon: React.FC<GenderIconProps> = (props) => {
  const { gender, ...rest } = props;

  if (!gender) {
    return null;
  }

  switch (gender) {
    case Gender.MALE: {
      return <MaleIcon {...rest} />;
    }
    case Gender.FEMALE: {
      return <FemaleIcon {...rest} />;
    }
    default: {
      return null;
    }
  }
};
