import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '.';
import { callRegisterUser, RegisterRequest, UserResponse } from '../../../App/apiWrapper';
import { DateType, Errors, RefIdType, ViolationLevel } from '../../../App/types';

export const registerUserThunk = createAsyncThunk<UserResponse, RegisterRequest>('register/user', async (input) => {
  return await callRegisterUser(input);
});

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setDateOfBirth: (state, action: PayloadAction<DateType>) => {
      state.dateOfBirth = action.payload;
    },
    setGender: (state, action: PayloadAction<RefIdType>) => {
      state.gender = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setMatchingPassword: (state, action: PayloadAction<string>) => {
      state.matchingPassword = action.payload;
    },
    resetRegistration: (state, _: PayloadAction) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUserThunk.fulfilled, (state, action) => {
        const userResponse = action.payload;
        const user = userResponse.user;
        if (user) {
          state.violationLevel = null;
          state.violationId = null;
          state.navigateTo = user.enabled ? '/login' : '/register_complete';
        }
      })
      .addCase(registerUserThunk.rejected, (state, action) => {
        const error = action.error;
        if (error.name !== Errors.TOO_MANY_REQUESTS_ERROR) {
          state.violationLevel = ViolationLevel.ERROR;
          state.violationId = 'SH.COMMON.ER.001';
        }
      });
  },
});

export const {
  setFirstName,
  setLastName,
  setGender,
  setEmail,
  setDateOfBirth,
  setPassword,
  setMatchingPassword,
  resetRegistration,
} = registerSlice.actions;

export default registerSlice.reducer;
