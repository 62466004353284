import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { AboutAuthorContent } from './AboutAuthorContent';
import DialogContent from '@mui/material/DialogContent';
import { DefaultFormButton } from '../common/DefaultFormButton';
import DialogActions from '@mui/material/DialogActions';
import { IntroContent } from './IntroContent';
import { ResponsiveDialog } from '../common/responsive/ResponsiveDialog';
import { useTranslation } from '../../features/i18n/useTranslation';

interface InfoDialogProps {
  open: boolean;

  onClose(): void;
}

const INTRO_TAB = '1';
const ABOUT_AUTHOR_TAB = '2';

export const INFO_DIALOG_ID = 'info-dialog';

export const InfoDialog: React.FC<InfoDialogProps> = ({ open, onClose }) => {
  const [tabValue, setTabValue] = React.useState(INTRO_TAB);
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    if (open) {
      setTabValue(INTRO_TAB);
      setVisible(true);
    }
  }, [open]);

  const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  }, []);

  const tabPanelProps = {
    sx: {
      padding: '8px 0 0 16px',
    },
  };

  const introLabel = useTranslation('SH.PG.INTRO');
  const aboutAuthorLabel = useTranslation('SH.PG.ABOUTAUTHOR');
  const tabsAriaLabel = useTranslation('SH.ARIA.INFOTABS');

  return (
    <ResponsiveDialog
      id={INFO_DIALOG_ID}
      breakpoints={{ maxWidth: 'lg' }}
      fullWidth
      open={open}
      onClose={onClose}
      {...(!visible ? { sx: { display: 'none' } } : {})}
      PaperProps={{
        'aria-label': tabValue === INTRO_TAB ? introLabel : aboutAuthorLabel,
      }}
    >
      <DialogContent
        style={{
          minHeight: '87vh',
          maxHeight: '90vh',
          padding: '0 16px',
        }}
      >
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label={tabsAriaLabel}>
              <Tab label={introLabel} value={INTRO_TAB} />
              <Tab label={aboutAuthorLabel} value={ABOUT_AUTHOR_TAB} />
            </TabList>
          </Box>
          <TabPanel value={INTRO_TAB} {...tabPanelProps}>
            <IntroContent />
          </TabPanel>
          <TabPanel value={ABOUT_AUTHOR_TAB} {...tabPanelProps}>
            <AboutAuthorContent />
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions>
        <DefaultFormButton id="SH.BTN.CLOSE" color="primary" onClick={onClose} />
      </DialogActions>
    </ResponsiveDialog>
  );
};
