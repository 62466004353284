import { SpelExpressionEvaluator } from 'spel2js';
import { ConditionDefinition } from '../../../App/apiWrapper';
import { Data, FieldIndex } from '../index';

export const conditionMatched = (data: Data, condition: ConditionDefinition, fieldIndices: FieldIndex[]): boolean => {
  const locals = {};
  const entries = condition.variables.entries || [];
  entries.forEach(({ key, value }) => (locals[key] = value));
  fieldIndices.forEach(({ idxVar, index }) => (locals[idxVar] = index));
  const expression = condition.expression;
  return SpelExpressionEvaluator.eval(expression, data, locals);
};
