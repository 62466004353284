export const sanitizeFilename = (rawValue: string): string => {
  return (
    rawValue
      // Replace illegal characters https://en.wikipedia.org/wiki/Filename#Comparison_of_filename_limitations
      .replace(/[\\/:"*?<>|]+/g, '')
      // Replace multiple whitespaces with a single space
      .replace(/\s\s+/g, ' ')
      // Replace spaces with underscore
      .replace(/\s/g, '_')
  );
};
