import { FieldIndex } from '../index';
import { replaceIndex } from './replaceIndex';

export const getRootAttribute = (
  pattern: string,
  fieldIndices: FieldIndex[],
  idxVarFunc: (fieldIndex: FieldIndex) => string,
): string => {
  if (!fieldIndices || fieldIndices.length === 0) {
    return pattern;
  }
  for (const fieldIndex of fieldIndices) {
    pattern = replaceIndex(pattern, idxVarFunc(fieldIndex), fieldIndex.index);
  }
  return pattern;
};
