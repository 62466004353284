import React from 'react';
import { DialogProps, useMediaQuery } from '@mui/material';
import { Breakpoint } from '@mui/system/createTheme';
import { theme } from '../../../App/theme';
import Dialog from '@mui/material/Dialog';

interface ResponsiveDialogProps extends DialogProps {
  breakpoints: {
    maxWidth: Breakpoint;
  };
}

export const ResponsiveDialog: React.FC<ResponsiveDialogProps> = (props) => {
  const { breakpoints, ...rest } = props;
  const {
    // Mobile first
    maxWidth = 'xs',
  } = breakpoints;
  const hasMaxWidthBreakpoint = useMediaQuery(theme.breakpoints.up(maxWidth));

  return <Dialog {...rest} {...(hasMaxWidthBreakpoint ? { maxWidth } : {})} />;
};
