import Cookies from 'js-cookie';
import { isSecure } from '../helpers/isSecure';
import { StringType } from '../App/types';
import { CookieObject } from '../hooks/CookieConsentContext';

class CookieService {
  static readCookieObject(name?: string): CookieObject | null | undefined {
    if (!name) {
      return null;
    }
    const value = Cookies.get(name);
    if (value) {
      return JSON.parse(value);
    }
  }

  static writeCookiesObject(name: string, cookieObject: CookieObject, expiresInDays: number) {
    const strCookieObj = JSON.stringify(cookieObject);
    if (strCookieObj !== '' && document !== undefined) {
      const attributes = {
        expires: expiresInDays,
        secure: isSecure(),
      };
      Cookies.set(name, strCookieObj, attributes);
    }
  }

  static getCookieOrDefault(name: string | undefined, defaultValue: StringType | undefined, expiresInDays: number) {
    const cookie = Cookies.get(name);
    if (cookie) {
      return cookie;
    }
    if (!name) {
      return defaultValue;
    }
    const attributes = {
      expires: expiresInDays,
      secure: isSecure(),
    };
    Cookies.set(name, defaultValue, attributes);
    return defaultValue;
  }

  static updateCookie(name: string | undefined, newValue: StringType, expiresInDays: number) {
    const attributes = {
      expires: expiresInDays,
      secure: isSecure(),
    };
    Cookies.set(name, newValue, attributes);
  }

  static deleteCookie(name: string | undefined) {
    Cookies.remove(name);
  }
}

export default CookieService;
