import { StringType } from '../App/types';
import { useCallback, useState } from 'react';
import CookieService from '../services/CookieService';

export const useCookie = (name: string | undefined, defaultValue: StringType | undefined, expiresInDays: number) => {
  const [value, setValue] = useState(() => CookieService.getCookieOrDefault(name, defaultValue, expiresInDays));

  const updateCookie = useCallback(
    (newValue: StringType) => {
      CookieService.updateCookie(name, newValue, expiresInDays);
      setValue(newValue);
    },
    [name, expiresInDays],
  );

  const deleteCookie = useCallback(() => {
    CookieService.deleteCookie(name);
    setValue(null);
  }, [name]);

  return [value, updateCookie, deleteCookie];
};
