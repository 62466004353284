import * as React from 'react';
import { useAppSelector } from '../../App/hooks';
import { selectShowError } from '../../features/ui/selectShowError';
import { ActionRef, DeleteStep, ScreenRef } from '../../App/types';
import { selectUserDeleteStep } from '../../features/user/selectUserDeleteStep';
import { ConfirmDeleteAccountDialog } from './ConfirmDeleteAccountDialog';
import { ViolationDialog } from '../common/ViolationDialog';
import { DeleteAccountDialog } from './DeleteAccountDialog';
import { selectDeleteProfile } from '../../features/user/deleteProfile/selectDeleteProfile';

interface DeleteAccountDialogWrapperProps {
  open: boolean;

  handleCancel(): void;
}

export const DELETE_ACCOUNT_DIALOG_WRAPPER_ID = 'delete-account-dialog-wrapper';

export const DeleteAccountDialogWrapper: React.FC<DeleteAccountDialogWrapperProps> = ({ open, handleCancel }) => {
  const deleteStep = useAppSelector(selectUserDeleteStep);
  const showError = useAppSelector(selectShowError);
  const deleteProfile = useAppSelector(selectDeleteProfile);
  const getDeleteAccountState = () => deleteProfile;

  if (!open || showError) {
    return null;
  }

  switch (deleteStep) {
    case DeleteStep.CONFIRM_DELETE: {
      return (
        <ConfirmDeleteAccountDialog
          dialogId={DELETE_ACCOUNT_DIALOG_WRAPPER_ID}
          open={true}
          handleCancel={handleCancel}
        />
      );
    }
    case DeleteStep.DELETE_REQUESTED: {
      return (
        <ViolationDialog
          dialogId={DELETE_ACCOUNT_DIALOG_WRAPPER_ID}
          titleId="SH.PG.ACCOUNTDELETIONREQUESTRECEIVED"
          elementId="SH.DLTPRFL.ALERT"
          open={true}
          onClose={handleCancel}
        />
      );
    }
    case DeleteStep.DELETE: {
      return (
        <DeleteAccountDialog
          dialogId={DELETE_ACCOUNT_DIALOG_WRAPPER_ID}
          open={true}
          handleCancel={handleCancel}
          screenRef={ScreenRef.DELETE_PROFILE}
          actionRef={ActionRef.DELETE}
          getData={getDeleteAccountState}
        />
      );
    }
    default: {
      return null;
    }
  }
};
