import { FieldViolation } from '../../App/types';

export type ViolationStateMap = {
  [key in string]?: FieldViolation[];
};

export interface ViolationState {
  map: ViolationStateMap;
}

export const initialState: ViolationState = {
  map: {},
};
