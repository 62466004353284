import { ChangePasswordRequest } from '../../../App/apiWrapper';
import { I18nTypes, ViolationLevel } from '../../../App/types';

export interface ChangePasswordState extends ChangePasswordRequest {
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
  violationArgs?: string[];
}

export const initialState: ChangePasswordState = {
  oldPassword: '',
  newPassword: '',
  matchingNewPassword: '',
  violationLevel: null,
  violationId: null,
  violationArgs: [],
};
