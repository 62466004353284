import { ReferenceData } from '../../App/apiWrapper';
import { CodeTableRef, ScreenRef } from '../../App/types';

export type RefData = {
  [key in CodeTableRef]?: ReferenceData[];
};

export type ScreenRefDataMap = {
  [key in ScreenRef]?: RefData;
};

export interface RefDataState {
  screenRefDataMap: ScreenRefDataMap;
  refresh: boolean;
}

export const initialState: RefDataState = {
  screenRefDataMap: {},
  refresh: false,
};
