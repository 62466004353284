import CheckIcon from '@mui/icons-material/Check';
import LanguageIcon from '@mui/icons-material/Language';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/hooks';
import { LocaleType } from '../../../App/types';
import { getLanguageByLocale } from '../../../features/i18n/getLanguageByLocale';
import { changeLanguageThunk } from '../../../features/i18n/i18nSlice';
import { selectLanguage } from '../../../features/i18n/selectLanguage';
import { selectLanguages } from '../../../features/i18n/selectLanguages';
import { selectLocale } from '../../../features/i18n/selectLocale';
import { useAuth } from '../../../hooks/useAuth';
import { useLocale } from '../../../hooks/useLocale';
import { selectLanguageSwitchEnabled } from '../../../features/config/selectLanguageSwitchEnabled';
import { useTranslation } from '../../../features/i18n/useTranslation';

const LANGUAGE_MENU_ID = 'language-menu';

export const LanguageSwitcher: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dispatch = useAppDispatch();
  const languages = useAppSelector(selectLanguages);
  const locale = useAppSelector(selectLocale);
  const language = useAppSelector(selectLanguage);
  const languageSwitchEnabled = useAppSelector(selectLanguageSwitchEnabled);
  const { userAuthenticated } = useAuth();
  const { update } = useLocale();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLocale = useCallback(
    (value: LocaleType) => {
      handleClose();
      if (value !== locale) {
        if (userAuthenticated) {
          const newLanguage = getLanguageByLocale(languages, value);
          if (newLanguage) {
            update(value);
            dispatch(changeLanguageThunk(newLanguage));
          }
        } else {
          update(value);
        }
      }
    },
    [dispatch, languages, locale, userAuthenticated, update],
  );

  const changeLanguageText = useTranslation('SH.BTN.CHANGELANGUAGE');

  if (!languageSwitchEnabled) {
    return null;
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={changeLanguageText}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-label={changeLanguageText}
            aria-controls={open ? LANGUAGE_MENU_ID : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color="inherit"
          >
            <Stack direction={'row'} spacing={0.5} sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <LanguageIcon />
              {language && <Typography variant={'body2'}>{language.name}</Typography>}
            </Stack>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        id={LANGUAGE_MENU_ID}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {languages?.map((language) => (
          <MenuItem onClick={() => changeLocale(language.locale)} key={language.id}>
            <Stack direction={'row'} spacing={1} sx={{ display: 'flex' }}>
              <Typography variant={'body2'}>{language.name}</Typography>
              {language.locale === locale && <CheckIcon color={'primary'} />}
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
