import { AvatarProps } from '@mui/material';
import { getAvatarColor } from './getAvatarColor';

export interface GetAvatarReturnType {
  sx: AvatarProps['sx'];
  children: AvatarProps['children'];
}

export const getAvatar = (name: string): GetAvatarReturnType => {
  const parts = name.split(' ');
  const first = parts[0].length > 0 ? parts[0][0].trim() : '';
  const last = parts.length > 1 ? (parts[1].length > 0 ? parts[1][0].trim() : '') : '';
  const children = `${first}${last}`;

  return {
    sx: {
      marginLeft: '8px',
      marginRight: '8px',
      bgcolor: getAvatarColor(name),
    },
    children: children.length > 0 ? children : '?',
  };
};
