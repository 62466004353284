import { LocaleType, MapType, NumberType, OptionalMapType, ServerTimeZone, StringType } from '../../App/types';

export interface GoogleAnalyticsState {
  enabled: boolean;
  domainMeasurementIds: OptionalMapType<string, string>;
  measurementId: string;
}

export interface MetaState {
  robots: string;
  title: string;
  subTitle: string;
  description: string;
  keywords: string;
}

export interface SeoState {
  defaults: OptionalMapType<LocaleType, MetaState>;
  metas: OptionalMapType<LocaleType, OptionalMapType<string, MetaState>>;
  preferredDomain: StringType;
}

export interface ConfigState {
  foundationYear: number;
  introVideoEnabled: boolean;
  consentCookieName?: string;
  consentCookieVersion?: string;
  consentCookieExpiresInDays: number;
  localeCookieName?: string;
  localeCookieExpiresInDays: number;
  systemDateFormat?: string;
  systemLocale?: LocaleType;
  languageSwitchEnabled: boolean;
  dateFormat?: string;
  timeZone: ServerTimeZone | null;
  userRoleNames: string[];
  adminRoleName: StringType;
  registrationExpiryTimeInHours: NumberType;
  resetPasswordExpiryTimeInHours: NumberType;
  forgotPasswordEnabled: boolean;
  deleteAccountEnabled: boolean;
  termsOfUseEnabled: boolean;
  googleAnalytics: GoogleAnalyticsState;
  seo: SeoState;
  roleMaxContactsCountMapping: MapType<string, number>;
  telegramChannelMapping: OptionalMapType<LocaleType, string>;
  initialized: boolean;
}

export const initialState: ConfigState = {
  foundationYear: 2024,
  introVideoEnabled: false,
  consentCookieExpiresInDays: 365,
  localeCookieExpiresInDays: 365,
  languageSwitchEnabled: false,
  timeZone: null,
  userRoleNames: [],
  adminRoleName: null,
  registrationExpiryTimeInHours: null,
  resetPasswordExpiryTimeInHours: null,
  forgotPasswordEnabled: false,
  deleteAccountEnabled: false,
  termsOfUseEnabled: false,
  googleAnalytics: {
    enabled: false,
    domainMeasurementIds: {},
    measurementId: '',
  },
  seo: {
    defaults: {},
    metas: {},
    preferredDomain: null,
  },
  roleMaxContactsCountMapping: {},
  telegramChannelMapping: {},
  initialized: false,
};
