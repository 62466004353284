import React from 'react';

export const clonePropertyInReactElement = (
  source: React.ReactElement,
  sourceProperty: string,
  targetProperty: string,
): React.ReactElement => {
  const props = source.props;
  if (props) {
    const sourcePropertyValue = props[sourceProperty];
    if (sourcePropertyValue) {
      return React.cloneElement(source, { [targetProperty]: sourcePropertyValue });
    }
  }
  return source;
};
