import { ButtonProps } from '@mui/material';
import React from 'react';
import { ActionRef, I18nTypes, ScreenRef } from '../../App/types';
import { FormButtonComponent } from './FormButton';

interface DefaultFormButtonProps extends ButtonProps {
  id: I18nTypes;
}

export const DefaultFormButton: React.FC<DefaultFormButtonProps> = (props) => {
  const { id, ...rest } = props;

  return <FormButtonComponent id={id} screenRef={ScreenRef.DEFAULT} actionRef={ActionRef.DEFAULT} {...rest} />;
};
