import { StringType } from '../../App/types';
import { hexToRgb } from './hexToRgb';
import { rgbToYIQ } from './rgbToYIQ';

export const contrast = (colorHex?: StringType, threshold: number = 128): string => {
  if (!colorHex) {
    return '#000';
  }
  const rgb = hexToRgb(colorHex);
  if (!rgb) {
    return '#000';
  }
  return rgbToYIQ(rgb) >= threshold ? '#000' : '#fff';
};
