import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './index';
import { callForgotPassword, ForgotPasswordRequest, GenericOutput } from '../../../App/apiWrapper';
import { Errors, ViolationLevel } from '../../../App/types';

export const forgotPasswordThunk = createAsyncThunk<GenericOutput, ForgotPasswordRequest>(
  'forgotPassword/user',
  async (input) => {
    return await callForgotPassword(input);
  },
);

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    resetForgetPassword: (state, _: PayloadAction) => {
      state.email = '';
      state.navigateTo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        const response = action.payload;
        const violations = response.violations;
        if (!violations || violations.length === 0) {
          state.violationLevel = null;
          state.violationId = null;
          state.navigateTo = '/forgot_password_complete';
        }
      })
      .addCase(forgotPasswordThunk.rejected, (state, action) => {
        const error = action.error;
        if (error.name !== Errors.TOO_MANY_REQUESTS_ERROR) {
          state.violationLevel = ViolationLevel.ERROR;
          state.violationId = 'SH.COMMON.ER.001';
        }
      });
  },
});

export const { setEmail, resetForgetPassword } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
