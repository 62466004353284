import { styled, SvgIcon as MuiSvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const SvgIcon = styled(MuiSvgIcon, {
  name: 'RatIcon',
  shouldForwardProp: (_) => true,
})<SvgIconProps>(() => ({
  '& .st0': {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
  enableBackground: 'new 0 0 122.88 102.6',
}));

export const RatIcon: React.FC<SvgIconProps> = ({ x = '0px', y = '0px', viewBox = '0 0 122.88 102.6', ...props }) => (
  <SvgIcon x={x} y={y} viewBox={viewBox} {...props}>
    <path
      className="st0"
      d="M40.33,54.05c-0.14,2.37-0.23,10.18-1.73,11.63c-1.59,1.55-4.45,0.05-6.71,1.42 c-0.94,0.57-1.21,1.49-0.82,2.78c2.86,0,9.4,0.6,11.62-0.97c1.36-0.96,2.64-3.62,3.55-5.17c0,0.88-0.05,2-0.08,3.53 c-0.21,10.7-6.38,4.52-8.78,7.73c-0.42,0.56-0.56,1.31-0.45,2.2c3.05,0,10.37,0.62,12.71-0.97c2.31-1.56,6.35-12.27,7.71-15.31 l5.88,1.47c3.8,0.85,4.7,1.87,6.2,3.23l-1.51,0.06c-1.65,0.07-4.08-0.06-5.39,1.04c-0.79,0.66-1.01,1.61-0.97,2.68l13.34-0.23 c-2.16-0.96-3.77-1.96-5.44-3.51c0.85,0.76,1.9,1.64,3.76,2.65c2.23,1.21,4.71,2.01,7.52,2.32c-1.26,1.22-2.88,2.11-4.83,2.69 c-3.33,1-8.47,0.28-10.52,2.4c-0.69,0.72-0.88,1.57-0.7,2.52c3.43-0.03,21.56-0.91,23.77-2.85c0.86-0.76,1.43-2,1.84-3.53 l1.65-3.81c4.58,0.19,11.4-2.18,15.14-6.08l-0.09,0.19c4.59,3.06,7.2,5.56,8.54,8.76c2.99,7.12,1.28,13.51-2.78,18.15 c-7.2,8.23-15.42,7.47-24.82,4.57c-8.95-3.65-17-7.67-26.8-1.41c-3.65,2.34-5.87,5.9-5.24,10.37c11.77-24.47,29.7,7.94,56.16-4.99 c16.09-7.86,13.25-32.32-2.3-41.15c0.03-0.56,0-1.12-0.11-1.7c-0.79-4.06-2.08-8.26-3.78-12.58 c-4.82-12.22-11.47-23.96-24.44-28.95c-6.7-2.58-13.68-2.31-20.88,0.28c-1.65,0.71-3.14,1.23-4.48,1.56 c-1.06,0.27-2.02,0.42-2.89,0.47c-1.94,0.1-3.41-0.56-4.75-1.53c2.64-2.45,5.23-5.2,4.51-7.01c-0.82-2.1-4.69-2.24-8.33-1.6 c1.22-7.68-8.21-6.64-11.64-0.15c0.02,0.13,0.04,0.27,0.06,0.39c-0.26-0.03-0.52-0.06-0.79-0.08c-8.13-0.69-17.77,2.83-25.8,6.35 c-1.77,0.78-6.88,2.23-6.44,4.34c0.96,4.64,9.97,7.03,13.47,7.79c-1.48,0.24-5.12,0.22-5.8,1.68c-0.36,0.77,0.19,1.25,1.48,1.51 c2.88,1.1,5.71,2.1,8.59,3c5.25,1.63,7.35,2.22,8.86,4.45c2.07,3.04,4.01,6.61,6.21,10.05C34.73,47.68,40.87,54.75,40.33,54.05 L40.33,54.05z M20.18,14.36c1.62,0,2.93,0.91,2.93,2.03c0,1.12-1.31,2.03-2.93,2.03c-1.62,0-2.93-0.91-2.93-2.03 C17.25,15.27,18.56,14.36,20.18,14.36L20.18,14.36z"
    />
  </SvgIcon>
);
