import React, { ComponentType, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { getAppInfoThunk, resetInfo } from './infoSlice';
import { selectAppInfoInitialized } from './selectAppInfoInitialized';
import { useSelector } from 'react-redux';
import { selectLocale } from '../i18n/selectLocale';
import { selectAppConfig } from '../config/selectAppConfig';
import { getMillisUntilStartOfNext } from '../../helpers/date/getMillisUntilStartOfNext';
import { getCurrentDateFormatted } from '../../helpers/date/getCurrentDateFormatted';
import { selectTimeoutConfig } from './selectTimeoutConfig';

export const withInfo = <T extends object>(WrappedComponent: ComponentType<T>) => {
  const HOC = (props: T) => {
    const dispatch = useAppDispatch();
    const initialized = useSelector(selectAppInfoInitialized);
    const timeoutConfig = useSelector(selectTimeoutConfig);
    const { dateFormat: serverFormat } = useAppSelector(selectAppConfig);
    const locale = useSelector(selectLocale);

    useEffect(() => {
      if (!initialized) {
        const input = {
          date: getCurrentDateFormatted(serverFormat),
          locale,
        };
        dispatch(getAppInfoThunk(input));
      }
    }, [dispatch, initialized, serverFormat, locale]);

    useEffect(() => {
      if (initialized) {
        const { value, unit } = timeoutConfig;
        const millis = getMillisUntilStartOfNext(value, unit);

        // Set up timeout to reset info at the start of the next day
        const intervalId = setTimeout(() => {
          dispatch(resetInfo());
        }, millis);

        return () => clearTimeout(intervalId);
      }
    }, [dispatch, initialized, timeoutConfig]);

    if (!initialized) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
  HOC.displayName = `withInfo(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
  return HOC;
};
