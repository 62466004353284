import React, { useCallback, useEffect, useMemo } from 'react';
import { LocaleType } from '../App/types';
import { useCookie } from './useCookie';
import { LocaleContext } from './LocaleContext';
import { selectLocale } from '../features/i18n/selectLocale';
import { useAppDispatch, useAppSelector } from '../App/hooks';
import { setLocale } from '../features/i18n/i18nSlice';
import { resetReferenceData } from '../features/refdata/refdataSlice';
import { selectLanguageSwitchEnabled } from '../features/config/selectLanguageSwitchEnabled';
import { selectSystemLocale } from '../features/config/selectSystemLocale';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { removePathParamByValue } from '../helpers/removePathParamByValue';
import { useNavigator } from './useNavigator';
import { isBot } from '../helpers/seo/isBot';
import { resetInfo } from '../features/info/infoSlice';

interface LocaleProviderProps {
  localeCookieName: string;
  localeCookieExpiresInDays: number;
  children: React.ReactNode;
}

export const LocaleProvider: React.FC<LocaleProviderProps> = (props) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { locale: localeParam } = useParams();
  const { localeCookieName, localeCookieExpiresInDays, children } = props;
  const localeInStore = useAppSelector(selectLocale);
  const [localeCookie, updateLocaleCookie] = useCookie(localeCookieName, localeInStore, localeCookieExpiresInDays);
  const languageSwitchEnabled = useAppSelector(selectLanguageSwitchEnabled);
  const systemLocale = useAppSelector(selectSystemLocale);
  const { userAgent } = useNavigator();
  const navigate = useNavigate();

  useEffect(() => {
    if (!languageSwitchEnabled && systemLocale && localeCookie !== systemLocale) {
      updateLocaleCookie(systemLocale);
    }
  }, [languageSwitchEnabled, systemLocale, localeCookie, updateLocaleCookie]);

  const locale = useMemo(() => (localeParam ? localeParam : localeCookie), [localeParam, localeCookie]);

  useEffect(() => {
    if (locale && localeInStore && locale !== localeInStore) {
      dispatch(setLocale(locale));
      dispatch(resetReferenceData());
      dispatch(resetInfo());
    }
  }, [dispatch, locale, localeInStore]);

  useEffect(() => {
    if (localeCookie !== locale) {
      updateLocaleCookie(locale);
    }
  }, [localeCookie, locale, updateLocaleCookie]);

  useEffect(() => {
    if (localeParam && userAgent && !isBot(userAgent)) {
      const newPath = removePathParamByValue(pathname, localeParam);
      navigate(newPath, { replace: true });
    }
  }, [navigate, pathname, localeParam, userAgent]);

  const update = useCallback(
    async (value: LocaleType) => {
      if (value !== localeCookie) {
        updateLocaleCookie(value);
      }
    },
    [localeCookie, updateLocaleCookie],
  );

  const value = useMemo(
    () => ({
      update,
    }),
    [update],
  );

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};
