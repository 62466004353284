import { DateFormat, DateType } from '../../App/types';
import dayjs, { Dayjs } from 'dayjs';

/**
 * Parses a date string based on the given date format.
 *
 * @param {DateType | undefined} value The date string to format.
 * @param {DateFormat | null} format The format string to use.
 */
export const parseDate = (value: DateType | undefined, format: DateFormat | null): Dayjs | null => {
  if (value && format) {
    return dayjs(value, format);
  }
  return null;
};
