import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../App/hooks';
import { selectUiState } from '../../features/ui/selectUiState';

export const AppSpinner: React.FC = () => {
  const { showLoader } = useAppSelector(selectUiState);

  if (!showLoader) {
    return null;
  }

  return (
    <Backdrop open={true} invisible={true} sx={{ zIndex: 999 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
