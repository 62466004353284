import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import CookieService from '../services/CookieService';
import { CookieObject } from './CookieConsentContext';

export const useConsentCookie = (consentCookieName: string | undefined, consentCookieExpiresInDays: number) => {
  const [value, setValue] = useState(() => CookieService.readCookieObject(consentCookieName));

  const updateCookie = useCallback(
    (newValue: CookieObject) => {
      if (consentCookieName) {
        CookieService.writeCookiesObject(consentCookieName, newValue, consentCookieExpiresInDays);
        setValue(newValue);
      }
    },
    [consentCookieName, consentCookieExpiresInDays],
  );

  const deleteCookie = useCallback(() => {
    CookieService.deleteCookie(consentCookieName);
    setValue(null);
  }, [consentCookieName]);

  return [value, updateCookie, deleteCookie] as [
    CookieObject,
    Dispatch<SetStateAction<CookieObject>>,
    Dispatch<SetStateAction<CookieObject>>,
  ];
};
