import Cookies from 'js-cookie';
import { csrfInfoSingleton } from '../helpers/csrf/csrf';
import AuthService from '../services/AuthService';
import { HttpError, TermsOfUseNotAcceptedError, TermsOfUseUpdatedError, TooManyRequestsError } from './types';

export const doGet = async (endpoint: RequestInfo, navigateToLoginOnAuthError: boolean = true) => {
  return doFetch(endpoint, 'GET', null, navigateToLoginOnAuthError);
};

export const doPost = async <T,>(
  endpoint: RequestInfo,
  request?: T,
  navigateToLoginOnAuthError: boolean = true,
  authTokenProvider?,
) => {
  return doFetch(endpoint, 'POST', request, navigateToLoginOnAuthError, authTokenProvider);
};

export const doPut = async <T,>(endpoint: RequestInfo, request?: T) => {
  return doFetch(endpoint, 'PUT', request);
};

export const doDelete = async <T,>(endpoint: RequestInfo, request?: T) => {
  return doFetch(endpoint, 'DELETE', request);
};

const addAuthorizationHeader = (headers, authTokenProvider?): void => {
  if (authTokenProvider) {
    const authToken = authTokenProvider();
    if (authToken) {
      headers.append('Authorization', authToken);
    }
  }
};

const addCsrfToken = (headers): void => {
  const { csrfInfo } = csrfInfoSingleton;
  const csrfToken = Cookies.get(csrfInfo.cookieName!);
  if (csrfToken) {
    headers.append(csrfInfo.headerName!, csrfToken);
  }
};

const doFetch = async <T,>(
  endpoint: RequestInfo,
  method: string,
  request?: T,
  navigateToLoginOnAuthError: boolean = true,
  authTokenProvider?,
) => {
  const headers = new Headers();
  headers.append('content-type', 'application/json');
  const responseFn = (response) => response.json();
  return fetchData(endpoint, headers, method, responseFn, request, navigateToLoginOnAuthError, authTokenProvider);
};

export const fetchData = async <T,>(
  endpoint: RequestInfo,
  headers: Headers,
  method: string,
  responseFn,
  request?: T,
  navigateToLoginOnAuthError: boolean = true,
  authTokenProvider?,
) => {
  addAuthorizationHeader(headers, authTokenProvider);
  addCsrfToken(headers);

  const options: RequestInit = {
    method,
    headers,
  };
  if (request) {
    options.body = JSON.stringify(request);
  }

  const handleResponse = (response) => {
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        // Auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        AuthService.resetAuthState();
        if (navigateToLoginOnAuthError) {
          window.history.pushState({}, '', '/login');
          window.location.reload();
        }
      } else {
        console.log(
          `HTTP ${method} Request to ${endpoint} has failed with status ${response.status} and text "${response.statusText}"`,
        );
        switch (response.status) {
          case 461:
            throw new TermsOfUseNotAcceptedError();
          case 462:
            throw new TermsOfUseUpdatedError();
          case 429:
            throw new TooManyRequestsError();
          default:
            throw new HttpError(response.status, response.statusText);
        }
      }
    }
    return responseFn(response);
  };

  return fetch(endpoint, options).then(handleResponse);
};
