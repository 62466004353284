import { I18nTypes, TermsOfUseStatus } from '../../App/types';

export interface ErrorState {
  id: I18nTypes;
}

export interface UiState {
  showLoader?: boolean;
  showTermsOfUse: boolean;
  showPrivacyPolicy: boolean;
  showCookieSettings: boolean;
  termsOfUseStatus: TermsOfUseStatus;
  showError: boolean;
  error: ErrorState | null;
  showAboutUs: boolean;
}

export const initialState: UiState = {
  showLoader: false,
  showTermsOfUse: false,
  showPrivacyPolicy: false,
  showCookieSettings: false,
  termsOfUseStatus: TermsOfUseStatus.UNKNOWN,
  showError: false,
  error: null,
  showAboutUs: false,
};
