import React from 'react';
import { HelpTextMarkdown } from '../common/markdown/HelpTextMarkdown';
import { withAboutUsText, WithAboutUsTextProps } from './withAboutUsText';

interface AboutUsContentProps extends WithAboutUsTextProps {}

export const AboutUsContentComponent: React.FC<AboutUsContentProps> = ({ text }) => {
  return <HelpTextMarkdown id={'about-us'} text={text} />;
};

export const AboutUsContent = withAboutUsText(AboutUsContentComponent);
