import { CookieObject } from '../../hooks/CookieConsentContext';

export const isCookieObjectValid = (cookieObject?: CookieObject | null, consentCookieVersion?: string): boolean => {
  if (!cookieObject || !consentCookieVersion) {
    return false;
  }
  const consents = cookieObject.consents;
  if (!consents) {
    return false;
  }
  return cookieObject.version === consentCookieVersion && consents.mandatory;
};
