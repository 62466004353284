import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { labelInitialState, LabelState } from '.';
import {
  callCreateLabel,
  callDeleteLabel,
  callGetLabels,
  callUpdateLabel,
  LabelItem,
  LabelRequest,
  LabelResponse,
  LabelsRequest,
  LabelsResponse,
} from '../../App/apiWrapper';
import { ColorType, StringType } from '../../App/types';

export const getLabelsThunk = createAsyncThunk<LabelsResponse, LabelsRequest>('label/search', async (request) => {
  return await callGetLabels(request);
});

export const createLabelThunk = createAsyncThunk<LabelResponse, LabelRequest>('label/create', async (input) => {
  return await callCreateLabel(input);
});

export const updateLabelThunk = createAsyncThunk<LabelResponse, LabelRequest>('label/update', async (input) => {
  return await callUpdateLabel(input);
});

export const deleteLabelThunk = createAsyncThunk<LabelResponse, LabelRequest>('label/delete', async (input) => {
  return await callDeleteLabel(input);
});

const handleSaveMutateLabel = (state: LabelState, response: LabelResponse) => {
  const violations = response.violations;
  if (!violations || violations.length === 0) {
    state.createOpen = false;
    state.editOpen = false;
    state.deleteOpen = false;
    state.refresh = true;
    state.selectedLabelItem = null;
    state.violationLevel = null;
    state.violationId = null;
    state.violationArgs = [];
  } else {
    state.refresh = false;
  }
};

export const labelSlice = createSlice({
  name: 'label',
  initialState: labelInitialState,
  reducers: {
    setLabelsRefresh: (state, action: PayloadAction<boolean>) => {
      state.refresh = action.payload;
    },
    setLabelCreateOpen: (state, action: PayloadAction<boolean>) => {
      state.createOpen = action.payload;
    },
    setLabelEditOpen: (state, action: PayloadAction<boolean>) => {
      state.editOpen = action.payload;
    },
    setLabelDeleteOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteOpen = action.payload;
    },
    setSelectedLabelItem: (state, action: PayloadAction<LabelItem | null>) => {
      state.selectedLabelItem = action.payload;
    },
    setSelectedLabelItemColor: (state, action: PayloadAction<ColorType>) => {
      if (state.selectedLabelItem) {
        state.selectedLabelItem.color = action.payload;
      }
    },
    setSelectedLabelItemName: (state, action: PayloadAction<string>) => {
      if (state.selectedLabelItem) {
        state.selectedLabelItem.name = action.payload;
      }
    },
    setSelectedLabelItemDescription: (state, action: PayloadAction<StringType>) => {
      if (state.selectedLabelItem) {
        state.selectedLabelItem.description = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLabelsThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const { labels } = payload;
        if (labels) {
          state.createOpen = false;
          state.editOpen = false;
          state.deleteOpen = false;
          state.refresh = false;
          state.selectedLabelItem = null;
          state.violationLevel = null;
          state.violationId = null;
          state.violationArgs = [];
          state.data = labels.items;
          state.totalCount = labels.totalCount;
          state.limit = labels.limit;
          state.offset = labels.offset;
        }
      })
      .addCase(createLabelThunk.fulfilled, (state, action) => {
        handleSaveMutateLabel(state, action.payload);
      })
      .addCase(updateLabelThunk.fulfilled, (state, action) => {
        handleSaveMutateLabel(state, action.payload);
      })
      .addCase(deleteLabelThunk.fulfilled, (state, action) => {
        handleSaveMutateLabel(state, action.payload);
      });
  },
});

export const {
  setLabelsRefresh,
  setLabelCreateOpen,
  setLabelEditOpen,
  setLabelDeleteOpen,
  setSelectedLabelItem,
  setSelectedLabelItemColor,
  setSelectedLabelItemName,
  setSelectedLabelItemDescription,
} = labelSlice.actions;

export default labelSlice.reducer;
