import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { callLogin, callLogout } from '../App/apiWrapper';
import { useAppDispatch, useAppSelector } from '../App/hooks';
import { ViolationLevel } from '../App/types';
import { resetHoroscope } from '../features/horoscope/horoscopeSlice';
import { resetPeople } from '../features/people/peopleSlice';
import { resetPerson } from '../features/person/personSlice';
import { resetLogin, resetViolation, setViolation } from '../features/user/login/loginSlice';
import { resetUser } from '../features/user/userSlice';
import { resetViolations, setViolations } from '../features/violations/violationsSlice';
import authService, { USER_AUTH_STATE } from '../services/AuthService';
import { AuthContext, AuthState } from './AuthContext';
import { useLocalStorage } from './useLocalStorage';
import { resetTermsOfUse } from '../features/i18n/i18nSlice';
import { resetUi } from '../features/ui/uiSlice';
import { resetReferenceData } from '../features/refdata/refdataSlice';
import { selectLanguage } from '../features/i18n/selectLanguage';

export const USER_AUTH_STATE_AUTHENTICATED = 'authenticated';

interface AuthProviderProps {
  authUserRoute: string;
  anonymousUserRoute: string;
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ authUserRoute, anonymousUserRoute, children }) => {
  const [userAuthState, setUserAuthState] = useLocalStorage(USER_AUTH_STATE, null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);

  const login = useCallback(
    async (data: AuthState) => {
      try {
        dispatch(resetViolations());
        dispatch(resetViolation());
        const loginRequest = {
          language: language ? language.id : null,
        };
        const authToken = authService.createAuthToken(data.email, data.password);
        const response = await callLogin(loginRequest, authToken);
        const { violations } = response;
        if (violations && violations.length > 0) {
          dispatch(setViolations(response));
        } else {
          setUserAuthState(USER_AUTH_STATE_AUTHENTICATED);
          dispatch(resetLogin());
          dispatch(resetReferenceData());
          dispatch(resetUser());
          dispatch(resetPerson());
          dispatch(resetPeople());
          dispatch(resetHoroscope());
          dispatch(resetTermsOfUse());
          navigate(authUserRoute, { replace: true });
        }
      } catch (err) {
        dispatch(
          setViolation({
            violationLevel: ViolationLevel.ERROR,
            ruleId: 'SH.AUTH.ER.001',
            args: [],
          }),
        );
      }
    },
    [setUserAuthState, navigate, authUserRoute, dispatch, language],
  );

  const logout = useCallback(async () => {
    setUserAuthState(null);
    dispatch(resetUi());
    await callLogout();
    navigate(anonymousUserRoute, { replace: true });
  }, [setUserAuthState, navigate, anonymousUserRoute, dispatch]);

  const value = useMemo(
    () => ({
      userAuthenticated: userAuthState && userAuthState === USER_AUTH_STATE_AUTHENTICATED,
      login,
      logout,
    }),
    [userAuthState, login, logout],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
