import { isValueContainArrayElementPart } from './isValueContainArrayElementPart';

export const AUTH_USER_ROUTE_EXCLUDES = [
  '/delete_account/',
  '/delete_account_token_invalid/',
  '/delete_account_complete',
];

export const isAuthUserRouteExclude = (path: string): boolean => {
  return isValueContainArrayElementPart(AUTH_USER_ROUTE_EXCLUDES, path);
};
