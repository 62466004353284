import React from 'react';
import { HelpTextMarkdown } from '../common/markdown/HelpTextMarkdown';
import { withPrivacyPolicyText, WithPrivacyPolicyTextProps } from './withPrivacyPolicyText';

interface PrivacyPolicyContentComponentProps extends WithPrivacyPolicyTextProps {}

export const PrivacyPolicyContentComponent: React.FC<PrivacyPolicyContentComponentProps> = ({ text }) => {
  return <HelpTextMarkdown text={text} />;
};

export const PrivacyPolicyContent = withPrivacyPolicyText(PrivacyPolicyContentComponent);
