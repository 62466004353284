import { DisplayRuleDefinition, FieldStateDefinition, FieldStateMap } from '../../App/apiWrapper';
import { ConsequenceEntry, Data, FieldKey } from './index';
import { evalBranches } from './internal/evalBranches';
import { extractFieldKeys } from './internal/extractFieldKeys';
import { fieldIndices } from './internal/fieldIndices';
import { indexedUiElementId } from './internal/indexedUiElementId';

export const applyDisplayRuleDefinitions = (
  defaultState: FieldStateMap,
  displayRuleDefs: DisplayRuleDefinition[],
  data: Data,
): void => {
  displayRuleDefs.forEach((displayRuleDefinition) => {
    const consequenceEntries: ConsequenceEntry[] = [];
    evalBranches(data, displayRuleDefinition.branches, consequenceEntries);
    consequenceEntries.forEach((entry) => updateState(defaultState, entry, data));
  });
};

const updateState = (defaultState: FieldStateMap, entry: ConsequenceEntry, data: Data): void => {
  const { key, value } = entry;
  value.forEach((fieldState) => {
    const { forEach } = fieldState;
    if (forEach) {
      const consequenceKeys: FieldKey[] = extractFieldKeys(data, forEach);
      consequenceKeys.forEach((consequenceKey) => {
        if (matches(consequenceKey, key)) {
          doUpdateState(consequenceKey, fieldState, defaultState);
        }
      });
    } else {
      doUpdateState(key, fieldState, defaultState);
    }
  });
};

const doUpdateState = (key: FieldKey | null, fieldState: FieldStateDefinition, defaultState: FieldStateMap) => {
  const indices = key ? fieldIndices(key) : [];
  const elementId = indexedUiElementId(fieldState, indices);
  const oldFieldState = defaultState[elementId];
  const { displayRuleType, codeTables, ...rest } = fieldState;
  defaultState[elementId] = {
    displayRuleType: displayRuleType ? displayRuleType : oldFieldState.displayRuleType,
    codeTables: codeTables ? codeTables : oldFieldState.codeTables,
    ...rest,
  };
};

const matches = (lhKey: FieldKey, rhKey: FieldKey | null): boolean => {
  const lhIndexMap = fieldIndexMap(lhKey);
  const rhIndexMap = fieldIndexMap(rhKey);
  return (
    sourceContainsAllEntriesFromTarget(lhIndexMap, rhIndexMap) ||
    sourceContainsAllEntriesFromTarget(rhIndexMap, lhIndexMap)
  );
};

type FieldIndexMap = {
  [key in string]: number;
};

const fieldIndexMap = (key: FieldKey | null): FieldIndexMap => {
  const indexMap: FieldIndexMap = {};
  const indices = fieldIndices(key);
  indices.forEach((index) => {
    indexMap[index.uiIdxVar] = index.index;
  });
  return indexMap;
};

const sourceContainsAllEntriesFromTarget = (source: FieldIndexMap, target: FieldIndexMap): boolean => {
  const targetKeys = Object.keys(target);
  for (let index = 0; index < targetKeys.length; index++) {
    const key = targetKeys[index];
    const targetElement = target[key];
    const sourceElement = source[key];
    if (targetElement !== sourceElement) {
      return false;
    }
  }
  return true;
};
