import React, { ComponentType, useEffect } from 'react';
import { useAppDispatch } from '../../App/hooks';
import { getAppConfigThunk } from './configSlice';
import { selectAppConfigInitialized } from './selectAppConfigInitialized';
import { useSelector } from 'react-redux';

interface WithConfigProps {}

export const withConfig = <T extends WithConfigProps>(WrappedComponent: ComponentType<T>) => {
  const HOC = (props: T) => {
    const dispatch = useAppDispatch();
    const appConfigInitialized = useSelector(selectAppConfigInitialized);

    useEffect(() => {
      if (!appConfigInitialized) {
        dispatch(getAppConfigThunk());
      }
    }, [dispatch, appConfigInitialized]);

    if (!appConfigInitialized) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
  HOC.displayName = `withConfig(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
  return HOC;
};
