import { styled, SvgIcon as MuiSvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const SvgIcon = styled(MuiSvgIcon, {
  name: 'HorseIcon',
  shouldForwardProp: (_) => true,
})<SvgIconProps>(() => ({
  '& .st0': {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
  enableBackground: 'new 0 0 122.88 110.29',
}));

export const HorseIcon: React.FC<SvgIconProps> = ({
  x = '0px',
  y = '0px',
  viewBox = '0 0 122.88 110.29',
  ...props
}) => (
  <SvgIcon x={x} y={y} viewBox={viewBox} {...props}>
    <path
      className="st0"
      d="M18.09,26.09c1.68,0.61,4.16-0.76,7.97-5.34c1.44,0.53,2.57,1.71,3.06,4.24c0.94,4.82-0.55,7.91-2.62,12.15 c-2.25,4.63-4.61,9.02-3.6,15.06c-4.13,0.51-7.93,0.38-11.38-0.46c-1.96-0.17-3.58-0.05-4.78,0.42c-1.22,0.47-2.01,1.3-2.3,2.55 c-0.7,4.68-2.53,7.66-4.41,10.56c-0.1,2.38-0.03,4.68,0.89,6.62l0.53,1.36l0.24,3.05c0.32,4.08,1.77,7.49,5.07,1.96 c0.34-2.11,0.02-4.22-0.95-6.33l-1.52-0.69c0.09-2.97,0.39-5.84,1.36-8.37c0.75-2.47,2.12-4.24,4.19-5.25l2.12-0.24l-1.24,3.18 c-0.29,3.36-0.72,6.42-1.89,8.02c-0.66,2.36-0.36,3.82,0.88,4.42c0.96,0.39,1.84,2.15,2.65,5.6c0.86,1.96,1.94,3.04,3.24,3.24 c1.36-0.34,1.6-3.03,1.06-7.31c-1.04-1.27-2.08-1.78-3.13-1.89c0.19-2.27,0.1-4.48-0.3-6.61c0.19-2.65,0.97-5.13,3.36-7.19 c0.92,2.5,4.89,3.59,9.91,4.19l3.3-3.42c8.75,8.3,18.93,13.06,28.78,9.44c0.72,0.51,1,2.52,1.25,6.01 c2.01,2.89,3.92,8.19,5.82,11.98c1.4,2.92,1.11,5.33-0.24,7.43c-3.04,1.29-5.95,3.26-7.75,5.38c-2.93,0.13-5.16,0.27-7,1.22 c-1.26,0.99-2.02,2.23-2.18,3.77l1.53,0.59l-1.71,2.36c0.61,2.05,2.61,2.6,6.52,2.44l2.27-3.91l4.36-1.24 c1.6-3.18,4.16-6.26,9.61-9.02c3.64,0.2,5.57-1.75,5.37-6.37c-1.84-4.18-2.51-8.19-0.83-11.85c7.72-4.75,12.49-12.02,11.91-20.17 c12.31-3.22-6.38,28.39,28.91,21.44c2.04-0.4,4.16-1.14,6.42-1.98c-4.56-0.78-17.68-4.03-19.99-9.55 c-5.91-8.38-12.1-14.5-18.69-17.4c-4.47-5.68-12.36-7.87-22.06-8.2c-2.9-0.26-5.44-1.03-7.43-2.59 c2.01-14.21-11.58-21.19-1.65-16.81C48.79,12.28,35.64,8.92,44.92,9.99c-4.77-4.36-10.79-6.77-19.34-5.25 c-1.82-2.65-6.17-8.32-4.84-1.53l-2.18-1.95l-0.12,6.84c-2.59,1.98-4.01,8.57-6.02,12.85c-1.71,1.7-2.37,3.74-1.83,6.9 C13.27,31.3,17.49,30.45,18.09,26.09L18.09,26.09z"
    />
  </SvgIcon>
);
