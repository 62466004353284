import { enUS, ruRU } from '@mui/x-date-pickers/locales';
import { LocalizationProviderProps } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization } from 'material-react-table/src/types';
import { LocaleType } from '../App/types';

interface MaterialReactTableLocalizationProps {
  mrtLocaleText: MRT_Localization;
}

type DatePickerLocalizationProps = Pick<LocalizationProviderProps<Dayjs, string>, 'adapterLocale' | 'localeText'>;

type LocalizationProps = DatePickerLocalizationProps & MaterialReactTableLocalizationProps;

type LocalizationMap = {
  [key in LocaleType]: LocalizationProps;
};

const localizationMap: LocalizationMap = {
  en: {
    localeText: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    mrtLocaleText: MRT_Localization_EN,
  },
  ru: {
    adapterLocale: 'ru',
    localeText: ruRU.components.MuiLocalizationProvider.defaultProps.localeText,
    mrtLocaleText: MRT_Localization_RU,
  },
};

class LocalizationService {
  getDatePickerLocalization = (locale?: LocaleType): DatePickerLocalizationProps => {
    if (!locale) {
      throw new Error('locale must not be null!');
    }
    return localizationMap[locale];
  };

  getMaterialReactTableLocalization = (locale?: LocaleType): MaterialReactTableLocalizationProps => {
    if (!locale) {
      throw new Error('locale must not be null!');
    }
    return localizationMap[locale];
  };
}

const localizationService = new LocalizationService();

export default localizationService;
