import { TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import { I18nTypes } from '../../App/types';
import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule';
import { useTranslation } from '../../features/i18n/useTranslation';
import { getViolationItems } from '../../features/violations/getViolationItems';
import { I18nFragment } from './I18nFragment';

export type FormTextFieldProps = TextFieldProps &
  WithDisplayRuleProps & {
    id: I18nTypes;
    placeholder?: I18nTypes;
    showLabel?: boolean;
  };

export const FormTextFieldComponent: React.FC<FormTextFieldProps> = (props) => {
  const {
    id,
    placeholder,
    hasViolation,
    violationMessages,
    violationLevel,
    actionRef,
    screenRef,
    getData,
    maxLength,
    slotProps = {},
    showLabel = true,
    ...rest
  } = props;

  const { htmlInput, ...restSlotProps } = slotProps;

  const placeholderText = useTranslation(placeholder);
  const helperTextItems = getViolationItems(violationMessages);

  return (
    <TextField
      {...rest}
      {...(showLabel ? { label: <I18nFragment id={id} /> } : {})}
      {...(hasViolation ? { error: true } : {})}
      {...(placeholderText ? { placeholder: placeholderText } : {})}
      helperText={<>{helperTextItems}</>}
      slotProps={{
        htmlInput: {
          ...htmlInput,
          ...(maxLength ? { maxLength } : {}),
        },
        ...restSlotProps,
      }}
    />
  );
};

export const FormTextField = withDisplayRule(FormTextFieldComponent);
