import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './index';
import {
  callGetForgotPasswordRequest,
  callResetPassword,
  ForgotPasswordRequest,
  GenericOutput,
  ResetPasswordRequest,
} from '../../../App/apiWrapper';
import { ViolationLevel } from '../../../App/types';

export const getForgotPasswordRequestThunk = createAsyncThunk<ForgotPasswordRequest, string>(
  'resetPassword/forgotPasswordRequest',
  async (token) => await callGetForgotPasswordRequest(token),
);

export const resetPasswordThunk = createAsyncThunk<GenericOutput, ResetPasswordRequest>(
  'resetPassword/user',
  async (input) => {
    return await callResetPassword(input);
  },
);

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setNewPassword: (state, action: PayloadAction<string>) => {
      state.newPassword = action.payload;
    },
    setMatchingNewPassword: (state, action: PayloadAction<string>) => {
      state.matchingNewPassword = action.payload;
    },
    resetResetPassword: (state, _: PayloadAction) => {
      state.token = null;
      state.email = null;
      state.initialized = false;
      state.newPassword = '';
      state.matchingNewPassword = '';
      state.navigateTo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPasswordThunk.fulfilled, (state, action) => {
        const response = action.payload;
        const violations = response.violations;
        if (!violations || violations.length === 0) {
          state.violationLevel = null;
          state.violationId = null;
          state.navigateTo = '/reset_password_complete';
        }
      })
      .addCase(getForgotPasswordRequestThunk.fulfilled, (state, action) => {
        const response = action.payload;
        state.email = response.email;
        state.initialized = true;
      })
      .addCase(resetPasswordThunk.rejected, (state, _) => {
        state.violationLevel = ViolationLevel.ERROR;
        state.violationId = 'SH.COMMON.ER.001';
      })
      .addCase(getForgotPasswordRequestThunk.rejected, (state, _) => {
        state.email = null;
        state.initialized = true;
      });
  },
});

export const { setToken, setEmail, setNewPassword, setMatchingNewPassword, resetResetPassword } =
  resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
