import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import { AlertProps } from '@mui/material';

export const TermsOfUseChangedAlert = styled(Alert)<AlertProps>(() => {
  return {
    '& .MuiAlert-message': {
      padding: 0,
    },
    '& .MuiTypography-root': {
      margin: '8px',
    },
    '& .MuiAlert-icon': {
      fontSize: '32px',
    },
  };
});
