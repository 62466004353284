import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useAppSelector } from '../../../../App/hooks';
import { selectDateFormat } from '../../../../features/user/selectDateFormat';
import { selectAppConfig } from '../../../../features/config/selectAppConfig';
import useMediaQuery from '@mui/system/useMediaQuery';
import { theme } from '../../../../App/theme';
import { useTranslation } from '../../../../features/i18n/useTranslation';
import Tooltip from '@mui/material/Tooltip';
import { reformatDate } from '../../../../helpers/date/reformatDate';

interface TodayWrapperProps {
  date: string;
}

export const TodayWrapper: React.FC<TodayWrapperProps> = (props) => {
  const dateFormat = useAppSelector(selectDateFormat);
  const { dateFormat: serverFormat } = useAppSelector(selectAppConfig);

  const { date } = props;

  const dateFormatted = useMemo(() => reformatDate(date, serverFormat, dateFormat), [date, dateFormat, serverFormat]);

  const today = useTranslation('SH.COMMON.TODAY');

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const dateFormattedElement = (
    <Typography component="span" variant={matches ? 'body1' : 'body2'} fontWeight="bold">
      {dateFormatted}
    </Typography>
  );

  return (
    <>
      {matches && (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography component="span" variant="body2">
            {today}:
          </Typography>
          {dateFormattedElement}
        </Stack>
      )}
      {!matches && (
        <Tooltip title={today}>
          <span>{dateFormattedElement}</span>
        </Tooltip>
      )}
    </>
  );
};
