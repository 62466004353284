import { I18nTypes, ViolationLevel } from '../../../App/types';
import { AuthState } from '../../../hooks/AuthContext';

export interface LoginState extends AuthState {
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
}

export const initialState: LoginState = {
  email: '',
  password: '',
  violationLevel: null,
  violationId: null,
};
