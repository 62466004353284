import React, { ComponentType, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { getIntroThunk } from '../../features/i18n/i18nSlice';
import { selectLocale } from '../../features/i18n/selectLocale';
import { selectResources } from '../../features/i18n/selectResources';

export interface WithIntroTextProps {
  text: string;
}

export const withIntroText = <T extends object>(WrappedComponent: ComponentType<T & WithIntroTextProps>) => {
  const HOC = (props: T) => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector(selectLocale);
    const resources = useAppSelector(selectResources);
    const { intro } = resources;

    useEffect(() => {
      if (!intro.initialized && locale) {
        dispatch(getIntroThunk(locale));
      }
    }, [dispatch, intro, locale]);

    if (!intro.initialized || !locale) {
      return null;
    }

    return <WrappedComponent text={intro.text} {...props} />;
  };
  HOC.displayName = `withIntroText(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
  return HOC;
};
