import {
  AnnualSign,
  Fate,
  HoroscopeType,
  PsychologicalType,
  RefIdType,
  SocialType,
  Temperament,
  ThinkingType,
} from '../../App/types';

export const getAnnualSignContactsKey = (
  horoscopeType: HoroscopeType | null,
  annualSign: AnnualSign,
  gender: RefIdType,
  thinkingType: ThinkingType | null,
  temperament: Temperament | null,
  psychologicalType: PsychologicalType | null,
  fate: Fate | null,
  socialType: SocialType | null,
): string => {
  return (
    `${horoscopeType ? horoscopeType : '?'}-${annualSign}-${gender ? gender : '?'}` +
    `-${thinkingType ? thinkingType : '?'}-${temperament ? temperament : '?'}` +
    `-${psychologicalType ? psychologicalType : '?'}-${fate ? fate : '?'}-${socialType ? socialType : '?'}`
  );
};
