import Typography from '@mui/material/Typography';
import React from 'react';

export const H6: React.FC = (props) => (
  <Typography
    {...props}
    variant={'subtitle1'}
    component={'h6'}
    sx={{
      fontWeight: 'bold',
      marginTop: '8px',
      marginBottom: '8px',
    }}
  />
);
