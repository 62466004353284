import { RegisterRequest } from '../../../App/apiWrapper';
import { I18nTypes, StringType, ViolationLevel, defaultDateType } from '../../../App/types';

export interface RegisterState extends RegisterRequest {
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
  navigateTo: StringType;
}

export const initialState: RegisterState = {
  personPk: null,
  firstName: '',
  lastName: '',
  dateOfBirth: defaultDateType,
  gender: null,
  email: '',
  password: '',
  matchingPassword: '',
  language: null,
  violationLevel: null,
  violationId: null,
  navigateTo: null,
};
