import React, { useCallback } from 'react';
import { I18nFragment } from '../../common/I18nFragment';
import Link from '@mui/material/Link';
import { useAppDispatch } from '../../../App/hooks';
import { setShowCookieSettings } from '../../../features/ui/uiSlice';
import { COOKIE_CONSENT_DIALOG_ID } from '../AppCookieConsent/CookieConsentDialog';

export const CookieSettingsLink: React.FC = () => {
  const dispatch = useAppDispatch();

  const showCookieSettings = useCallback(() => {
    dispatch(setShowCookieSettings(true));
  }, [dispatch]);

  return (
    <Link
      variant="body2"
      component="button"
      aria-controls={COOKIE_CONSENT_DIALOG_ID}
      aria-haspopup="true"
      onClick={showCookieSettings}
    >
      <I18nFragment id="SH.BTN.COOKIESETTINGS" />
    </Link>
  );
};
