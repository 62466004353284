import { LocaleType } from '../App/types';
import { createContext } from 'react';

interface LocaleContextState {
  update(value: LocaleType): void;
}

const defaultValue: LocaleContextState = {
  update(_: LocaleType): void {},
};

export const LocaleContext = createContext<LocaleContextState>(defaultValue);
