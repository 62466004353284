import { I18nTypes, StringType, ViolationLevel } from '../../../App/types';

export interface ResetPasswordState {
  token?: StringType;
  initialized: boolean;
  email: StringType;
  newPassword: string;
  matchingNewPassword: string;
  navigateTo: StringType;
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
}

export const initialState: ResetPasswordState = {
  token: null,
  initialized: false,
  email: null,
  newPassword: '',
  matchingNewPassword: '',
  navigateTo: null,
};
