import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from '../../features/i18n/useTranslation';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import { SvgIconProps } from '@mui/material';

type CloseIconButtonProps = Omit<IconButtonProps, 'aria-label'> & {
  iconProps?: SvgIconProps;
};

export const CloseIconButton: React.FC<CloseIconButtonProps> = (props) => {
  const { iconProps, ...rest } = props;

  return (
    <IconButton aria-label={useTranslation('SH.BTN.CLOSE')} {...rest}>
      <CloseIcon {...iconProps} />
    </IconButton>
  );
};
