import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import {
  changePasswordThunk,
  resetChangePassword,
  setMatchingNewPassword,
  setNewPassword,
  setOldPassword,
} from '../../features/user/changePassword/changePasswordSlice';
import { selectChangePassword } from '../../features/user/changePassword/selectChangePassword';
import { PageFragmentInitProps, withPageFragmentInit } from '../../features/withPageFragmentInit';
import { FormButton } from '../common/FormButton';
import { FormPasswordField } from '../common/FormPasswordField';
import { ViolationBanner } from '../common/ViolationBanner';
import TextField from '@mui/material/TextField';
import { selectUserProfile } from '../../features/user/selectUserProfile';

export interface ChangePasswordDialogFragmentComponentProps extends PageFragmentInitProps {
  open: boolean;

  handleCancel?(): void;
}

export const ChangePasswordDialogFragmentComponent: React.FC<ChangePasswordDialogFragmentComponentProps> = ({
  open,
  handleCancel,
  screenRef,
  actionRef,
}) => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectUserProfile);
  const changePasswordState = useAppSelector(selectChangePassword);

  const props = { screenRef, actionRef };

  const { oldPassword, newPassword, matchingNewPassword, violationLevel, violationId, violationArgs } =
    changePasswordState;

  useEffect(() => {
    if (open) {
      dispatch(resetChangePassword());
    }
  }, [open, dispatch]);

  const doChangePassword = () => {
    const passwordDto = {
      oldPassword,
      newPassword,
      matchingNewPassword,
    };
    dispatch(changePasswordThunk(passwordDto));
  };

  const handleOldPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setOldPassword(event.target.value));
  };

  const handleNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setNewPassword(event.target.value));
  };

  const handleMatchingNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setMatchingNewPassword(event.target.value));
  };

  const oldPasswordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (oldPasswordRef.current) {
      oldPasswordRef.current.focus();
    }
  }, []);

  return (
    <>
      <DialogTitle>
        {violationLevel && <ViolationBanner level={violationLevel} id={violationId} args={violationArgs} />}
      </DialogTitle>
      <DialogContent>
        <Stack component="form" spacing={1}>
          <TextField value={userProfile.email} sx={{ display: 'none' }} autoComplete="username" />
          <FormPasswordField
            id="SH.CHGPWD.OLDPASSWORD"
            margin="normal"
            fullWidth
            name="oldPassword"
            inputRef={oldPasswordRef}
            autoComplete="current-password"
            onChange={handleOldPasswordChange}
            value={oldPassword}
            {...props}
          />
          <FormPasswordField
            id="SH.CHGPWD.NEWPASSWORD"
            margin="normal"
            fullWidth
            name="newPassword"
            autoComplete="new-password"
            onChange={handleNewPasswordChange}
            value={newPassword}
            {...props}
          />
          <FormPasswordField
            id="SH.CHGPWD.CONFIRMNEWPASSWORD"
            margin="normal"
            fullWidth
            name="confirmNewPassword"
            autoComplete="new-password"
            onChange={handleMatchingNewPasswordChange}
            value={matchingNewPassword}
            {...props}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        {handleCancel && <FormButton id="SH.BTN.CLOSE" color="primary" onClick={handleCancel} {...props} />}
        <FormButton id="SH.BTN.CHANGEPASSWORD" onClick={doChangePassword} variant="contained" {...props} />
      </DialogActions>
    </>
  );
};

export const ChangePasswordDialogFragment = withPageFragmentInit(ChangePasswordDialogFragmentComponent);
