import {
  DisplayRuleDefinition,
  FieldMetadataDefinition,
  FieldMetadataMap,
  FieldStateDefinition,
  FieldStateMap,
  LabelItem,
} from '../../App/apiWrapper';
import { Calendar, DataState, MapEntries } from '../../App/types';
import { HoroscopeState } from '../horoscope';
import { LoginState } from '../user/login';
import { RegisterState } from '../user/register';
import { ForgotPasswordState } from '../user/forgotPassword';
import { PersonState } from '../person';
import { PersonInput } from '../../components/dialogs/PersonDialogFragment';
import { DeleteProfileState } from '../user/deleteProfile';
import { UserState } from '../user';
import { ChangePasswordState } from '../user/changePassword';
import { Data } from '../../helpers/displayRules';

export interface DisplayRuleState {
  defaultState: MapEntries<FieldStateDefinition> | null;
  currentState?: FieldStateMap | null;
  metadata: MapEntries<FieldMetadataDefinition> | null;
  currentMetadata?: FieldMetadataMap | null;
  displayRuleDefs: DisplayRuleDefinition[] | null;
  dataState: DataState;
}

export type DisplayRuleStateMap = {
  [key in string]: DisplayRuleState;
};

export const initialState: DisplayRuleStateMap = {};

export const displayRuleInitialState: DisplayRuleState = {
  defaultState: null,
  metadata: null,
  displayRuleDefs: null,
  dataState: DataState.INITIAL,
};

export type DataType =
  | HoroscopeState
  | LoginState
  | RegisterState
  | ForgotPasswordState
  | PersonState
  | PersonInput
  | DeleteProfileState
  | UserState
  | ChangePasswordState
  | LabelItem
  | Calendar
  | Data;
