import { ForEachDefinition } from '../../../App/apiWrapper';
import { Data, FieldIndex, FieldKey } from '../index';
import { getNextData } from './getNextData';
import { sizeOf } from './sizeOf';

export const addFieldKey = (
  data: Data,
  forEach: ForEachDefinition,
  fieldIndices: FieldIndex[],
  fieldKeys: FieldKey[],
): void => {
  if (forEach === null) {
    const key: FieldKey = { fieldIndices };
    fieldKeys.push(key);
  } else {
    const size = sizeOf(data, forEach.attr);
    for (let index = 0; index < size; index++) {
      const { idxVar, uiIdxVar } = forEach;
      const indices: FieldIndex[] = [...fieldIndices];
      const fieldIndex: FieldIndex = {
        index,
        idxVar,
        uiIdxVar,
      };
      indices.push(fieldIndex);
      const nextData = getNextData(data, forEach, index);
      addFieldKey(nextData, forEach.forEach, indices, fieldKeys);
    }
  }
};
