import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, ViolationState } from '.';
import { HasFieldViolations } from '../../App/apiWrapper';
import { groupBy } from '../../helpers/groupBy';
import { individualHoroscopeThunk } from '../horoscope/horoscopeSlice';
import { createLabelThunk, deleteLabelThunk, updateLabelThunk } from '../label/labelSlice';
import { createPersonThunk, deletePersonThunk, updatePersonThunk } from '../people/peopleSlice';
import { getPersonThunk } from '../person/personSlice';
import { changePasswordThunk } from '../user/changePassword/changePasswordSlice';
import { registerUserThunk } from '../user/register/registerSlice';
import { deleteCurrentUserAccountThunk, updateCurrentUserPersonalInfoThunk } from '../user/userSlice';
import { forgotPasswordThunk } from '../user/forgotPassword/forgotPasswordSlice';
import { resetPasswordThunk } from '../user/resetPassword/resetPasswordSlice';
import { deleteUserProfileThunk } from '../user/deleteProfile/deleteProfileSlice';

const handleViolations = (state: ViolationState, action: PayloadAction<HasFieldViolations>) => {
  state.map = groupBy(action.payload.violations, 'fieldId');
};

export const violationsSlice = createSlice({
  name: 'violations',
  initialState,
  reducers: {
    setViolations: (state, action: PayloadAction<HasFieldViolations>) => {
      handleViolations(state, action);
    },
    resetViolations: (state, _: PayloadAction) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(individualHoroscopeThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(registerUserThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(resetPasswordThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(changePasswordThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(updateCurrentUserPersonalInfoThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(createPersonThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(getPersonThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(updatePersonThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(deletePersonThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(createLabelThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(updateLabelThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(deleteLabelThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(deleteCurrentUserAccountThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(deleteUserProfileThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      });
  },
});

export const { setViolations, resetViolations } = violationsSlice.actions;

export default violationsSlice.reducer;
