import React from 'react';
import { Stack } from '@mui/material';
import { AppBar } from './AppBar/AppBar';
import { AppFooter } from './AppFooter/AppFooter';
import { withGoogleAnalytics } from './withGoogleAnalytics';

interface AppContentProps {
  content: React.JSX.Element | null;
}

const AppLayoutComponent: React.FC<AppContentProps> = ({ content }) => (
  <Stack
    spacing={0}
    sx={{
      minHeight: '98vh',
    }}
  >
    <AppBar />
    {content}
    <AppFooter />
  </Stack>
);

export const AppLayout = withGoogleAnalytics(AppLayoutComponent);
