import { RefData } from '.';
import { ReferenceData } from '../../App/apiWrapper';
import { CodeTableRef } from '../../App/types';

export const refDataValues = (attributeRef: CodeTableRef | null, entries?: RefData): ReferenceData[] => {
  if (!attributeRef || !entries) {
    return [];
  }
  return entries[attributeRef] || [];
};
