import React from 'react';
import { clonePropertyInReactElement } from './clonePropertyInReactElement';

export const clonePropertyInReactElementChildren = (
  source: React.ReactElement,
  sourceProperty: string,
  targetProperty: string,
) => {
  const children: React.ReactElement[] = [];
  React.Children.toArray(source.props.children).forEach((value) => {
    const child = clonePropertyInReactElement(value as React.ReactElement, sourceProperty, targetProperty);
    children.push(child);
  });
  return React.cloneElement(source, {}, children);
};
