import { Box, DialogContent } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { selectUiState } from '../../features/ui/selectUiState';
import { setShowPrivacyPolicy } from '../../features/ui/uiSlice';
import { DefaultFormButton } from '../common/DefaultFormButton';
import DialogActions from '@mui/material/DialogActions';
import { PrivacyPolicyContent } from './PrivacyPolicyContent';
import { ResponsiveDialog } from '../common/responsive/ResponsiveDialog';
import DialogTitle from '@mui/material/DialogTitle';
import { I18nFragment } from '../common/I18nFragment';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../App/theme';

export const PRIVACY_POLICY_DIALOG_ID = 'privacy-policy-dialog';

export const PrivacyPolicyDialog: React.FC = () => {
  const [visible, setVisible] = React.useState(false);

  const dispatch = useAppDispatch();
  const { showPrivacyPolicy: open } = useAppSelector(selectUiState);

  useEffect(() => {
    if (open) {
      setVisible(true);
    }
  }, [open]);

  const onClose = useCallback(() => {
    dispatch(setShowPrivacyPolicy(false));
  }, [dispatch]);

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ResponsiveDialog
      id={PRIVACY_POLICY_DIALOG_ID}
      breakpoints={{ maxWidth: 'lg' }}
      fullWidth
      open={open}
      aria-labelledby="privacy-policy-dialog-title"
      onClose={onClose}
      {...(!visible ? { sx: { display: 'none' } } : {})}
    >
      <DialogTitle id="privacy-policy-dialog-title" component="h5">
        <Box
          {...(!matches
            ? {
                sx: {
                  fontSize: '1.15rem',
                },
              }
            : {})}
        >
          <I18nFragment id="SH.PG.PRIVACYPOLICY" />
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent
        style={{
          minHeight: '70vh',
          padding: '0 16px',
        }}
      >
        <PrivacyPolicyContent />
      </DialogContent>
      <DialogActions>
        <DefaultFormButton id="SH.BTN.CLOSE" color="primary" onClick={onClose} />
      </DialogActions>
    </ResponsiveDialog>
  );
};
