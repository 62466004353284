import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';
import { AnnualSign } from '../../../../App/types';
import { RatIcon } from '../../../icons/annualSign/RatIcon';
import { OxIcon } from '../../../icons/annualSign/OxIcon';
import { TigerIcon } from '../../../icons/annualSign/TigerIcon';
import { CatIcon } from '../../../icons/annualSign/CatIcon';
import { DragonIcon } from '../../../icons/annualSign/DragonIcon';
import { SnakeIcon } from '../../../icons/annualSign/SnakeIcon';
import { HorseIcon } from '../../../icons/annualSign/HorseIcon';
import { GoatIcon } from '../../../icons/annualSign/GoatIcon';
import { MonkeyIcon } from '../../../icons/annualSign/MonkeyIcon';
import { RoosterIcon } from '../../../icons/annualSign/RoosterIcon';
import { DogIcon } from '../../../icons/annualSign/DogIcon';
import { BoarIcon } from '../../../icons/annualSign/BoarIcon';

interface AnnualSignIconProps extends SvgIconProps {
  annualSign: AnnualSign;
}

export const AnnualSignIcon: React.FC<AnnualSignIconProps> = (props) => {
  const { annualSign, ...rest } = props;

  switch (annualSign) {
    case AnnualSign.RAT: {
      return <RatIcon {...rest} />;
    }
    case AnnualSign.OX: {
      return <OxIcon {...rest} />;
    }
    case AnnualSign.TIGER: {
      return <TigerIcon {...rest} />;
    }
    case AnnualSign.CAT: {
      return <CatIcon {...rest} />;
    }
    case AnnualSign.DRAGON: {
      return <DragonIcon {...rest} />;
    }
    case AnnualSign.SNAKE: {
      return <SnakeIcon {...rest} />;
    }
    case AnnualSign.HORSE: {
      return <HorseIcon {...rest} />;
    }
    case AnnualSign.GOAT: {
      return <GoatIcon {...rest} />;
    }
    case AnnualSign.MONKEY: {
      return <MonkeyIcon {...rest} />;
    }
    case AnnualSign.ROOSTER: {
      return <RoosterIcon {...rest} />;
    }
    case AnnualSign.DOG: {
      return <DogIcon {...rest} />;
    }
    case AnnualSign.BOAR: {
      return <BoarIcon {...rest} />;
    }
    default: {
      return null;
    }
  }
};
