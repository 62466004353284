import { Options } from '@popperjs/core';
import { FormDatePickerProps } from './FormDatePickerProps';

// https://popper.js.org/docs/v2/modifiers/
export const createPopperModifiers = (props: FormDatePickerProps): Options['modifiers'] => {
  const { popperCenterOnTheScreen = true } = props;

  const modifiers: Options['modifiers'] = [];

  if (popperCenterOnTheScreen) {
    // https://github.com/floating-ui/floating-ui/issues/642
    const modifier = {
      name: 'computeStyles',
      enabled: true,
      fn({ state }) {
        state.styles.popper = {
          ...state.styles.popper,
          position: 'fixed',
          left: `${(window.innerWidth - state.rects.popper.width) / 2}px`,
          top: '50%',
          transform: 'translateY(-50%)',
        };
        return state;
      },
    };
    modifiers.push(modifier);
  }
  return modifiers;
};
