import React, { ComponentType, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/hooks';
import { getCookieConsentMessageThunk } from '../../../features/i18n/i18nSlice';
import { selectLocale } from '../../../features/i18n/selectLocale';
import { selectResources } from '../../../features/i18n/selectResources';

export interface WithCookieConsentMessageTextProps {
  text: string;
}

export const withCookieConsentMessageText = <T extends object>(
  WrappedComponent: ComponentType<T & WithCookieConsentMessageTextProps>,
) => {
  const HOC = (props: T) => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector(selectLocale);
    const resources = useAppSelector(selectResources);
    const { cookieConsentMessage } = resources;

    useEffect(() => {
      if (!cookieConsentMessage.initialized && locale) {
        dispatch(getCookieConsentMessageThunk(locale));
      }
    }, [dispatch, cookieConsentMessage, locale]);

    if (!cookieConsentMessage.initialized || !locale) {
      return null;
    }

    return <WrappedComponent text={cookieConsentMessage.text} {...props} />;
  };
  HOC.displayName = `withCookieConsentMessageText(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
  return HOC;
};
