import React, { FC, ReactElement } from 'react';
import { Box, Container, Divider, Stack, useMediaQuery } from '@mui/material';
import { Copyright } from './Copyright';
import Grid from '@mui/material/Grid2';
import { TermsOfUseLink } from './TermsOfUseLink';
import { AboutUsLink } from './AboutUsLink';
import { PrivacyPolicyLink } from './PrivacyPolicyLink';
import { theme } from '../../../App/theme';
import { CookieSettingsLink } from './CookieSettingsLink';

export const AppFooter: FC = (): ReactElement => {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        marginTop: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Divider sx={{ marginTop: 2 }} />
      <Container
        component="main"
        maxWidth={false}
        sx={{
          marginTop: 2,
          marginBottom: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid size={8}>
            <Stack {...(matches ? { direction: 'row' } : { direction: 'column' })} spacing={matches ? 2 : 1}>
              <TermsOfUseLink />
              <PrivacyPolicyLink />
              <CookieSettingsLink />
              <AboutUsLink />
            </Stack>
          </Grid>
          <Grid size={4}>
            <Copyright />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
