import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReferenceData } from '../../App/apiWrapper';
import { DateType, I18nTypes, NumberType, RefIdType, ViolationLevel } from '../../App/types';
import { PageFragmentInitProps, withPageFragmentInit } from '../../features/withPageFragmentInit';
import { FormDatePicker } from '../common/datePicker/FormDatePicker';
import { FormAlert } from '../common/FormAlert';
import { FormButton } from '../common/FormButton';
import { FormTextField } from '../common/FormTextField';
import { I18nFragment } from '../common/I18nFragment';
import { FormRdrMultiSelectChip } from '../common/multiSelect/FormRdrMultiSelectChip';
import { FormRdrSelect } from '../common/select/FormRdrSelect';
import { ViolationBanner } from '../common/ViolationBanner';

export interface PersonInput {
  personPk: NumberType;
  firstName: string;
  lastName: string;
  dateOfBirth: DateType;
  gender: RefIdType;
  labels: string[];
}

export interface PersonDialogFragmentComponentProps extends PageFragmentInitProps {
  titleId?: I18nTypes;
  open: boolean;
  person: PersonInput;
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
  violationArgs?: string[];

  handleCancel?(): void;

  handleSave(data: PersonInput): void;
}

export const PersonDialogFragmentComponent: React.FC<PersonDialogFragmentComponentProps> = ({
  titleId,
  open,
  person,
  handleCancel,
  handleSave,
  screenRef,
  actionRef,
  violationLevel,
  violationId,
  violationArgs,
}) => {
  const [firstName, setFirstName] = useState(person.firstName);
  const [lastName, setLastName] = useState(person.lastName);
  const [dateOfBirth, setDateOfBirth] = useState(person.dateOfBirth);
  const [gender, setGender] = useState(person.gender);
  const [labels, setLabels] = useState(person.labels);

  const props = {
    screenRef,
    actionRef,
  };

  const handleFirstNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setFirstName(event.target.value);
  }, []);

  const handleLastNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setLastName(event.target.value);
  }, []);

  const handleDateOfBirthChange = useCallback((newValue) => {
    setDateOfBirth(newValue);
  }, []);

  const handleGenderChange: SelectInputProps<RefIdType>['onChange'] = useCallback((event) => {
    setGender(event.target.value);
  }, []);

  const handleLabelsChange = useCallback(
    (newValue: ReferenceData[]) => {
      const labels = newValue.map((value) => value.id);
      setLabels(labels);
    },
    [setLabels],
  );

  useEffect(() => {
    if (open) {
      setFirstName(person.firstName);
      setLastName(person.lastName);
      setDateOfBirth(person.dateOfBirth);
      setGender(person.gender);
      setLabels(person.labels);
    }
  }, [open, person]);

  const onSave = () => {
    const data = {
      personPk: person.personPk,
      firstName,
      lastName,
      dateOfBirth,
      gender,
      labels,
    };
    handleSave(data);
  };

  const firstNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

  return (
    <>
      <DialogTitle id={titleId}>
        {titleId && <I18nFragment id={titleId} />}
        <FormAlert id="SH.PRSN.ALERT" {...props} />
        {violationLevel && <ViolationBanner level={violationLevel} id={violationId} args={violationArgs} />}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ marginTop: 1 }}>
          <FormTextField
            id="SH.PRSN.FIRSTNAME"
            inputRef={firstNameRef}
            fullWidth
            variant="outlined"
            value={firstName}
            onChange={handleFirstNameChange}
            {...props}
          />
          <FormTextField
            id="SH.PRSN.LASTNAME"
            fullWidth
            variant="outlined"
            value={lastName}
            onChange={handleLastNameChange}
            {...props}
          />
          <FormDatePicker
            id="SH.PRSN.DATEOFBIRTH"
            value={dateOfBirth}
            handleValueChange={handleDateOfBirthChange}
            fullWidth
            {...props}
          />
          <FormRdrSelect
            id="SH.PRSN.GENDER"
            fullWidth
            name="gender"
            onChange={handleGenderChange}
            value={gender}
            {...props}
          />
          <FormRdrMultiSelectChip id={'SH.PRSN.LABELS'} values={labels} onValueChange={handleLabelsChange} {...props} />
        </Stack>
      </DialogContent>
      <DialogActions>
        {handleCancel && <FormButton id="SH.BTN.CLOSE" color="primary" onClick={handleCancel} {...props} />}
        <FormButton id="SH.BTN.SAVE" variant="contained" color="primary" onClick={onSave} {...props} />
      </DialogActions>
    </>
  );
};

export const PersonDialogFragment = withPageFragmentInit(PersonDialogFragmentComponent);
