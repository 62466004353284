import React from 'react';
import { useAppSelector } from '../../../App/hooks';
import { withDisplayRule } from '../../../features/displayRules/withDisplayRule';
import { selectRefDataValues } from '../../../features/refdata/selectRefDataValues';
import { FormSelect, FormSelectProps } from './FormSelect';

export type FormRdrSelectComponentProps = Omit<FormSelectProps, 'options' | 'multiple' | 'autoWidth' | 'native'>;

const FormRdrSelectComponent: React.FC<FormRdrSelectComponentProps> = (props) => {
  const { screenRef, actionRef, id } = props;
  const options = useAppSelector(selectRefDataValues(screenRef, actionRef, id));

  return <FormSelect {...props} options={options} />;
};

export const FormRdrSelect = withDisplayRule(FormRdrSelectComponent);
