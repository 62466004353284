import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import React, { FC, MouseEvent, useState } from 'react';
import { FormTextField, FormTextFieldProps } from './FormTextField';
import { useTranslation } from '../../features/i18n/useTranslation';

export const FormPasswordField: FC<FormTextFieldProps> = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseEvents = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label={useTranslation('SH.ARIA.SHOWHIDEPASSWORD')}
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseEvents}
        onMouseUp={handleMouseEvents}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormTextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      slotProps={{
        input: { endAdornment },
      }}
    />
  );
};
