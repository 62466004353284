import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { getLanguageReferenceDataThunk } from '../refdata/refdataSlice';
import { getTranslationMapThunk } from './i18nSlice';
import { selectLocale } from './selectLocale';
import { selectTranslationsInitialized } from './selectTranslationsInitialized';

export const withTranslations = (WrappedComponent: React.ComponentType) => {
  const ComponentWithTranslations = () => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector(selectLocale);
    const translationsInitialized = useAppSelector(selectTranslationsInitialized);

    useEffect(() => {
      if (!translationsInitialized && locale) {
        dispatch(getLanguageReferenceDataThunk());
        dispatch(getTranslationMapThunk(locale));
      }
    }, [dispatch, translationsInitialized, locale]);

    if (!translationsInitialized || !locale) {
      return null;
    }

    return <WrappedComponent />;
  };
  ComponentWithTranslations.displayName = `withTranslations(
    ${WrappedComponent.displayName || WrappedComponent.name || 'Component'}
  )`;

  return ComponentWithTranslations;
};
