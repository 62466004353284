import { I18nTypes, StringType, ViolationLevel } from '../../../App/types';

export interface ForgotPasswordState {
  email: string;
  navigateTo: StringType;
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
}

export const initialState: ForgotPasswordState = {
  email: '',
  navigateTo: null,
};
