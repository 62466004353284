import React from 'react';
import { CookieConsentModal } from './CookieConsentModal';
import { useAppSelector } from '../../../App/hooks';
import { selectAppConfig } from '../../../features/config/selectAppConfig';
import { isValueContainArrayElementPart } from '../../../helpers/isValueContainArrayElementPart';
import { COOKIE_POLICY_PATH } from './types';

export const AppCookieConsent: React.FC = () => {
  const { consentCookieName, consentCookieVersion } = useAppSelector(selectAppConfig);
  const { pathname } = location;

  if (!consentCookieName || !consentCookieVersion || isValueContainArrayElementPart([COOKIE_POLICY_PATH], pathname)) {
    return null;
  }

  return <CookieConsentModal />;
};
