import React from 'react';
import { useAppSelector } from '../../../../App/hooks';
import useMediaQuery from '@mui/system/useMediaQuery';
import { theme } from '../../../../App/theme';
import Stack from '@mui/material/Stack';
import { selectAppInfo } from '../../../../features/info/selectAppInfo';
import { AnnualSignWrapper } from './AnnualSignWrapper';
import { TodayWrapper } from './TodayWrapper';

export const AppInfo: React.FC = () => {
  const info = useAppSelector(selectAppInfo);
  const { date, dateAnnualSign, yearAnnualSign } = info;

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  if (!date || !dateAnnualSign || !yearAnnualSign) {
    return null;
  }

  return (
    <Stack direction="row" spacing={matches ? 2 : 1} sx={{ alignItems: 'center', marginBottom: '4px' }}>
      <TodayWrapper date={date} />
      <AnnualSignWrapper id="SH.COMMON.YEAR" value={yearAnnualSign} />
      <AnnualSignWrapper id="SH.COMMON.DAY" value={dateAnnualSign} />
    </Stack>
  );
};
