import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, RefDataState } from '.';
import {
  getLanguageReferenceData,
  getReferenceDataByCodeTableRefs,
  getReferenceDataMap,
  ReferenceDataMap,
  ReferenceDataMapEntry,
} from '../../App/apiWrapper';
import { CodeTableRef, LocaleType, ScreenRef } from '../../App/types';
import { changeLanguageThunk } from '../i18n/i18nSlice';
import { parseReferenceData } from './parseReferenceData';

export const getReferenceDataMapThunk = createAsyncThunk<
  ReferenceDataMap,
  { screenRef: ScreenRef; locale: LocaleType }
>('refdata/referencedatamap', async ({ screenRef, locale }) => {
  return await getReferenceDataMap(screenRef, locale);
});

export const getLanguageReferenceDataThunk = createAsyncThunk<ReferenceDataMapEntry[]>(
  'refdata/languagereferencedata',
  async () => {
    return await getLanguageReferenceData();
  },
);

export const getReferenceDataByCodeTableRefsThunk = createAsyncThunk<
  ReferenceDataMapEntry[],
  {
    codeTableRefs: CodeTableRef[];
    locale: LocaleType;
  }
>('refdata/referencedatabycodetablerefs', async ({ codeTableRefs, locale }) => {
  return await getReferenceDataByCodeTableRefs(codeTableRefs, locale);
});

const handleReferenceDataMap = (action: PayloadAction<ReferenceDataMap>, state: RefDataState) => {
  const { screen, entries } = action.payload;
  state.screenRefDataMap[screen] = parseReferenceData(entries);
};

const doResetReferenceData = (state: RefDataState) => {
  state.screenRefDataMap = {};
  state.refresh = true;
};

export const refdataSlice = createSlice({
  name: 'refdata',
  initialState,
  reducers: {
    resetReferenceData: (state, _: PayloadAction) => doResetReferenceData(state),
    setReferenceDataRefresh: (state, action: PayloadAction<boolean>) => {
      state.refresh = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReferenceDataMapThunk.fulfilled, (state, action) => {
        handleReferenceDataMap(action, state);
      })
      .addCase(getReferenceDataByCodeTableRefsThunk.fulfilled, (state, action) => {
        const entries = action.payload;
        const newRefData = parseReferenceData(entries);
        const codeTableRefs = Object.keys(newRefData);
        const screens = Object.keys(state.screenRefDataMap);
        screens.forEach((screen) => {
          const refData = state.screenRefDataMap[screen];
          codeTableRefs.forEach((codeTableRef) => {
            if (refData[codeTableRef]) {
              refData[codeTableRef] = newRefData[codeTableRef];
            }
          });
        });
      })
      .addCase(changeLanguageThunk.fulfilled, (state, action) => {
        const output = action.payload;
        const preferences = output.preferences;
        if (preferences) {
          doResetReferenceData(state);
        }
      });
  },
});

export const { resetReferenceData, setReferenceDataRefresh } = refdataSlice.actions;

export default refdataSlice.reducer;
