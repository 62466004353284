import { createSelector } from 'reselect';
import { ActionRef, DataState, ScreenRef } from '../../App/types';
import { getDisplayRuleKey } from '../../helpers/displayRules/getDisplayRuleKey';
import { selectDisplayRuleStateMap } from './selectDisplayRuleStateMap';

export const selectDisplayRulesDataState = (screen: ScreenRef, action: ActionRef) =>
  createSelector([selectDisplayRuleStateMap], (displayRuleStateMap) => {
    const key = getDisplayRuleKey(screen, action);
    const displayRuleState = displayRuleStateMap[key];
    return displayRuleState ? displayRuleState.dataState : DataState.INITIAL;
  });
