import { I18nTypes, StringType, ViolationLevel } from '../../../App/types';

export interface DeleteProfileState {
  token?: StringType;
  initialized: boolean;
  email: StringType;
  password: string;
  navigateTo: StringType;
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
}

export const initialState: DeleteProfileState = {
  token: null,
  initialized: false,
  email: null,
  password: '',
  navigateTo: null,
};
