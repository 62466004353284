import React, { ComponentType, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { getTermsOfUseThunk } from '../../features/i18n/i18nSlice';
import { selectLocale } from '../../features/i18n/selectLocale';
import { selectResources } from '../../features/i18n/selectResources';

export interface WithTermsOfUseTextProps {
  text: string;
}

export const withTermsOfUseText = <T extends object>(WrappedComponent: ComponentType<T & WithTermsOfUseTextProps>) => {
  const HOC = (props: T) => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector(selectLocale);
    const resources = useAppSelector(selectResources);
    const { termsOfUse } = resources;

    useEffect(() => {
      if (!termsOfUse.initialized && locale) {
        dispatch(getTermsOfUseThunk(locale));
      }
    }, [dispatch, termsOfUse, locale]);

    if (!termsOfUse.initialized || !locale) {
      return null;
    }

    return <WrappedComponent text={termsOfUse.text} {...props} />;
  };
  HOC.displayName = `withTermsOfUseText(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
  return HOC;
};
