import { createContext } from 'react';

export type CookieObject = {
  version: string;
  consents: {
    [key: string]: boolean;
  };
};

interface CookieConsentContextState {
  valid: boolean;
  gaAccepted: boolean;

  accept(value: CookieObject): void;
}

const defaultValue: CookieConsentContextState = {
  valid: false,
  gaAccepted: false,
  accept(_: CookieObject): void {},
};

export const CookieConsentContext = createContext<CookieConsentContextState>(defaultValue);
