import React, { useEffect, useMemo } from 'react';
import { useAppSelector } from '../../App/hooks';
import { selectLocale } from '../../features/i18n/selectLocale';
import { removePathParamByValue } from '../../helpers/removePathParamByValue';
import { selectSeoMetas } from '../../features/config/selectSeoMetas';
import { selectSeoDefaults } from '../../features/config/selectSeoDefaults';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { updateElementContentById } from '../../helpers/dom/updateElementContentById';

export const SeoMetaInfo: React.FC = () => {
  const locale = useAppSelector(selectLocale);
  const { pathname } = useLocation();
  const { locale: localeParam } = useParams();
  const seoMetas = useAppSelector(selectSeoMetas);
  const seoDefaults = useAppSelector(selectSeoDefaults);

  const meta = useMemo(() => {
    if (locale) {
      const path = localeParam ? removePathParamByValue(pathname, localeParam) : pathname;
      const paths = seoMetas[locale];
      return (paths && paths[path]) || seoDefaults[locale];
    }
    return null;
  }, [locale, localeParam, pathname, seoMetas, seoDefaults]);

  useEffect(() => {
    if (meta) {
      updateElementContentById('seo-title', meta.title);
      updateElementContentById('seo-sub-title', meta.subTitle);
      updateElementContentById('seo-description', meta.description);
      updateElementContentById('seo-keywords', meta.keywords);
    }
  }, [meta]);

  if (!meta) {
    return null;
  }

  return (
    <Helmet>
      <html lang={locale} />
      <title>{meta.title}</title>
      <meta name="robots" content={meta.robots} />
      <meta name="title" content={meta.title} />
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
    </Helmet>
  );
};
