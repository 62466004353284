import { PersonalImageCountStateType } from '.';
import { PersonalImageCountType } from '../../App/types';

export const getPersonalImageContactsState = (contacts: PersonalImageCountType): PersonalImageCountStateType => {
  const personalImageContacts = {};
  Object.keys(contacts).forEach((key) => {
    personalImageContacts[key] = { contactCount: contacts[key] };
  });
  return personalImageContacts;
};
