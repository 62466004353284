import { SxProps, TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { I18nTypes } from '../../App/types';
import { I18nFragment } from './I18nFragment';

type FormTypographyProps = TypographyProps & {
  component?: React.ElementType;
  id: I18nTypes;
  boxSx?: SxProps;
};

export const FormTypography: React.FC<FormTypographyProps> = (props) => {
  const { id, boxSx, ...rest } = props;

  return (
    <Typography {...rest}>
      <Box sx={boxSx}>
        <I18nFragment id={id} />
      </Box>
    </Typography>
  );
};
