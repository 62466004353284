import { ManipulateType } from 'dayjs';
import { getCurrentDate } from './getCurrentDate';

/**
 * Returns the number of milliseconds until the start of the next unit of time in the user timezone.
 *
 * @param value - amount of time units
 * @param unit - The unit of time (e.g., 'day', 'hour', 'minute', etc.)
 * @returns The number of milliseconds until the start of the next unit of time
 */
export const getMillisUntilStartOfNext = (value: number, unit: ManipulateType): number => {
  const currentTime = getCurrentDate();
  const nextTime = currentTime.add(value, unit).startOf(unit);
  return nextTime.diff(currentTime);
};
