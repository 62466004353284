import { createSelector } from 'reselect';
import { ActionRef, ScreenRef } from '../../App/types';
import { getDisplayRulesState } from './getDisplayRulesState';
import { selectDisplayRuleStateMap } from './selectDisplayRuleStateMap';

export const selectDisplayRuleType = (screen: ScreenRef, action: ActionRef, id: string) =>
  createSelector([selectDisplayRuleStateMap], (displayRules) => {
    const currentState = getDisplayRulesState(displayRules, screen, action);
    return currentState ? (currentState[id] ? currentState[id]!.displayRuleType : null) : null;
  });
