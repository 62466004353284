import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorState, initialState, UiState } from '.';
import { callAcceptTermsOfUse, TermsOfUseInfo } from '../../App/apiWrapper';
import { TermsOfUseStatus } from '../../App/types';
import { getUserProfileThunk } from '../user/userSlice';

export const acceptTermsOfUseThunk = createAsyncThunk<TermsOfUseInfo, void>('ui/acceptTermsOfUse', async () => {
  return await callAcceptTermsOfUse();
});

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    startLoader: (state) => {
      state.showLoader = true;
      return state;
    },
    stopLoader: (state) => {
      state.showLoader = false;
      return state;
    },
    setShowTermsOfUse: (state: UiState, action: PayloadAction<boolean>) => {
      state.showTermsOfUse = action.payload;
    },
    setShowPrivacyPolicy: (state: UiState, action: PayloadAction<boolean>) => {
      state.showPrivacyPolicy = action.payload;
    },
    setShowCookieSettings: (state: UiState, action: PayloadAction<boolean>) => {
      state.showCookieSettings = action.payload;
    },
    setTermsOfUseStatus: (state: UiState, action: PayloadAction<TermsOfUseStatus>) => {
      state.termsOfUseStatus = action.payload;
    },
    setShowError: (state: UiState, action: PayloadAction<boolean>) => {
      state.showError = action.payload;
    },
    setError: (state: UiState, action: PayloadAction<ErrorState | null>) => {
      state.error = action.payload;
    },
    setShowAboutUs: (state: UiState, action: PayloadAction<boolean>) => {
      state.showAboutUs = action.payload;
    },
    resetUi: (state: UiState, _: PayloadAction) => {
      state.showLoader = initialState.showLoader;
      state.showTermsOfUse = initialState.showTermsOfUse;
      state.showPrivacyPolicy = initialState.showPrivacyPolicy;
      state.termsOfUseStatus = initialState.termsOfUseStatus;
      state.showError = initialState.showError;
      state.error = initialState.error;
      state.showAboutUs = initialState.showAboutUs;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileThunk.fulfilled, (state, action) => {
        const profile = action.payload;
        const { termsOfUseStatus } = profile;
        state.termsOfUseStatus = termsOfUseStatus;
        state.showTermsOfUse = termsOfUseStatus !== TermsOfUseStatus.ACCEPTED;
      })
      .addCase(acceptTermsOfUseThunk.fulfilled, (state, action) => {
        const profile = action.payload;
        const { termsOfUseStatus } = profile;
        state.termsOfUseStatus = termsOfUseStatus;
        state.showTermsOfUse = false;
      });
  },
});

export const {
  startLoader,
  stopLoader,
  setShowTermsOfUse,
  setShowPrivacyPolicy,
  setShowCookieSettings,
  setTermsOfUseStatus,
  setShowError,
  setError,
  setShowAboutUs,
  resetUi,
} = uiSlice.actions;

export default uiSlice.reducer;
