import { RGB, StringType } from '../../App/types';

export const hexToRgb = (hex?: StringType): RGB | null => {
  if (!hex || hex.length === 0) {
    return null;
  }
  const result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
