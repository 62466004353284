import React from 'react';
import { HelpTextMarkdown } from '../common/markdown/HelpTextMarkdown';
import { withAboutAuthorText, WithAboutAuthorTextProps } from './withAboutAuthorText';

interface AboutAuthorContentProps extends WithAboutAuthorTextProps {}

export const AboutAuthorContentComponent: React.FC<AboutAuthorContentProps> = ({ text }) => {
  return <HelpTextMarkdown text={text} />;
};

export const AboutAuthorContent = withAboutAuthorText(AboutAuthorContentComponent);
