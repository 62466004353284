import React, { useState } from 'react';
import { useCookieConsent } from '../../../hooks/useCookieConsent';
import { CookieConsentDialog } from './CookieConsentDialog';
import { Backdrop } from '@mui/material';

export const CookieConsentModal: React.FC = () => {
  const { valid } = useCookieConsent();
  const [show, setShow] = useState(!valid);

  return (
    <Backdrop open={show}>
      <CookieConsentDialog
        show={show}
        setShow={setShow}
        defaultGaChecked={true}
        showAcceptButton={true}
        acceptId="SH.BTN.ACCEPTCOOKIES"
      />
    </Backdrop>
  );
};
