import Typography from '@mui/material/Typography';
import React from 'react';

export const getViolationItems = (violationMessages?: string[], fontSize = '0.75rem'): React.ReactElement[] => {
  const helperTextItems: React.ReactElement[] = [];
  if (violationMessages) {
    violationMessages.forEach((value, index) =>
      helperTextItems.push(
        <Typography
          component={'span'}
          key={index}
          sx={{
            fontSize: fontSize,
          }}
        >
          {value}
        </Typography>,
      ),
    );
  }
  return helperTextItems;
};
