import { PeopleFilteringRequest, PersonItem } from '../../App/apiWrapper';
import { I18nTypes, ViolationLevel } from '../../App/types';

export interface PeopleState {
  filtering: PeopleFilteringRequest;
  data: PersonItem[];
  totalCount: number;
  limit: number;
  offset: number;
  createOpen: boolean;
  editOpen: boolean;
  deleteOpen: boolean;
  labelsOpen: boolean;
  refresh: boolean;
  selectedPersonItem: PersonItem | null;
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
  violationArgs?: string[];
}

export const initialState: PeopleState = {
  filtering: {
    firstName: null,
    lastName: null,
    dateOfBirth: null,
    annualSigns: [],
    calendarSigns: [],
    personalImages: [],
    thinkingTypes: [],
    temperaments: [],
    psychologicalTypes: [],
    fates: [],
    socialTypes: [],
    labels: [],
  },
  data: [],
  totalCount: 0,
  offset: 0,
  limit: 10,
  createOpen: false,
  editOpen: false,
  deleteOpen: false,
  labelsOpen: false,
  refresh: false,
  selectedPersonItem: null,
  violationLevel: null,
  violationId: null,
};
