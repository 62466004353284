import { LabelItem } from '../../App/apiWrapper';
import { I18nTypes, ViolationLevel } from '../../App/types';

export interface LabelState {
  data: LabelItem[];
  totalCount: number;
  limit: number;
  offset: number;
  refresh: boolean;
  createOpen: boolean;
  editOpen: boolean;
  deleteOpen: boolean;
  selectedLabelItem: LabelItem | null;
  violationLevel: ViolationLevel | null;
  violationId: I18nTypes | null;
  violationArgs: string[];
}

export const labelInitialState: LabelState = {
  data: [],
  totalCount: 0,
  offset: 0,
  limit: 5,
  refresh: false,
  createOpen: false,
  editOpen: false,
  deleteOpen: false,
  selectedLabelItem: null,
  violationLevel: null,
  violationId: null,
  violationArgs: [],
};
