import { FieldMetadataDefinition, FieldMetadataMap } from '../../App/apiWrapper';
import { MapEntries } from '../../App/types';
import { Data, FieldKey } from './index';
import { extractFieldKeys } from './internal/extractFieldKeys';
import { fieldIndices } from './internal/fieldIndices';
import { indexedUiElementId } from './internal/indexedUiElementId';

export const applyDefaultMetadata = (
  defaultMetadata: MapEntries<FieldMetadataDefinition>,
  data: Data,
): FieldMetadataMap => {
  const metadata: FieldMetadataMap = {};
  const entries = defaultMetadata.entries || [];
  entries.forEach((metadataDefinition) => {
    const { elementId, forEach, ...rest } = metadataDefinition;
    if (forEach == null) {
      metadata[elementId] = {
        ...rest,
      };
    } else {
      const fieldKeys: FieldKey[] = extractFieldKeys(data, forEach);
      fieldKeys.forEach((key) => {
        const indices = fieldIndices(key);
        const elementId = indexedUiElementId(metadataDefinition, indices);
        metadata[elementId] = {
          ...rest,
        };
      });
    }
  });
  return metadata;
};
